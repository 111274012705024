export default {
    form: {
        button: 'Begin nu',
        proposal: 'Plak hier je videolink, bijvoorbeeld https://www.youtube.com/watch?v=vojBp3s0K8o',
        note: '<strong>Disclaimer</strong> :&nbsp; Het converteren en downloaden van YouTube-video\'s die beschermd zijn door auteursrechten, schendt de algemene voorwaarden van YouTube. Kopieer geen inhoud zonder toestemming van de auteursrechteigenaar.',
        note_facebook: '{noteTag} Krijgt u de foutmelding "Sorry, dit type link wordt momenteel niet ondersteund" bij het proberen te downloaden van een Facebook-video op uw PC/Mac? Klik dan op de knop "Downloaden" om het nog een paar keer te proberen, of klik op {fbNote} om te weten hoe u dit kunt oplossen.',
        note_name: 'Opmerking',
        note_facebookCont: 'klik hier',
        note_facebookUrl: 'https://www.download4.cc/guide/how-to-download-facebook-videos-on-pc.html',
        terms_before: 'Door gebruik te maken van onze diensten, accepteert u onze {terms}',
        terms_after: 'gebruiksvoorwaarden',
        terms_url: 'https://www.download4.cc/nl/terms-of-use.html',
        sub_button: "Gratis downloaden",
        sub_app_button: "Nu downloaden",
        sub_app_mobile_button: "Nu installeren",
        sub_fixed_button: "App installeren",
        buy_now: 'Koop nu'
    },
    loadingCont: 'Analyseren van de downloadlink, een ogenblik geduld...',
    failure: {
        fail_title: 'Sorry, het is niet gelukt om de bijbehorende downloadlink te analyseren',
        notsupport_title: 'Sorry, dit type link wordt momenteel niet ondersteund',
        tips1: '1. Klik eerst op de knop "DOWNLOADEN" om het probleem nog een paar keer te proberen op te lossen. Als u probeert Facebook-video\'s te downloaden met behulp van de link die is gekopieerd van de knop "Link kopiëren" in Facebook, ga dan terug naar de Facebook-video pagina, zoek de video die u wilt downloaden, dubbelklik op de video en kopieer de URL direct naar download4.cc om de video opnieuw te downloaden.',
        tips2: '2. Zorg er ten tweede voor dat de video/muziek link toegankelijk is en normaal kan worden afgespeeld.',
        tips3: '3. Ten derde kan de downloadlink mogelijk niet worden geanalyseerd vanwege een instabiel netwerk. Controleer uw netwerk of schakel over naar een stabieler netwerk en probeer het nog een paar keer. Als u na meerdere pogingen nog steeds niet kunt analyseren, klik dan op "{feedbackTag}" in de foutmelding en we zullen het zo snel mogelijk oplossen.',
        tips4: '4. Voor sommige video\'s is software vereist om te downloaden. Na installatie van de software kunt u onbeperkt alle online video\'s downloaden.{subscribeTag}',
        feedback_name: 'Feedback',
        feedback_success_cont: 'Feedback succesvol!',
        subscribe_name: 'Meer leren >'
    },
    subscribe: {
        title: 'De software komt binnenkort uit, u kunt zich nu abonneren. Na de abonnement sturen we u de gratis video/muziek download software zo snel mogelijk!',
        button: 'Abonneer nu',
        proposal: 'Voer alstublieft het e-mailadres in',
        subscribe_success_cont: 'Abonnement succesvol!',
        subscribe_fail_cont: 'Ongeldig e-mailformaat',
    },
    downloadCont: {
        durationPre: 'Duur',
        tips_title: 'Download tips:',
        tips1: '<strong>Voor Computer: </strong>Rechtermuisklik op de downloadknop en selecteer "Link opslaan als" om te downloaden;',
        tips2: '<strong>Voor Android: </strong>Houd de downloadknop ingedrukt en selecteer "Link opslaan als" om te downloaden;',
        tips3: '<strong>Voor iOS: </strong>Gebruik de ingebouwde browser van de Documents App om op deze site te downloaden.'
    },
    downloadOther: {
        video: 'Video',
        audio: 'Audio',
        audio_and_video: 'Video & Audio',
        silent_video: 'Stille video',
        quality: 'Kwaliteit',
        format: 'Formaat',
        size: 'Grootte',
        download: 'Downloaden',
        note: '{noteTag}: Kunt u de benodigde formaten niet vinden? Klik op een willekeurig formaat om te downloaden en gebruik vervolgens {hitpawTag} om het naar het gewenste formaat te converteren.',
        note_name: 'Opmerking',
        hitpaw_url: 'https://www.vcows.net/purchase/buy-vcows.html',
        hfv_converter: 'Videodownloader voor desktop',
    },
    downloadMp3: {
        wait_title: 'MP3 downloaden',
        wait_down1: 'het kan een paar ogenblikken duren...',
        wait_down2: 'Wacht alstublieft terwijl het bestand wordt voorbereid voor downloaden...',
        success_title: 'Downloaden succesvol',
        success_cont: 'Controleer alstublieft de map.',
        fail_cont: 'Analyse mislukt! Gebruik TubePaw Downloader voor Desktop om opnieuw te proberen.',
        too_large: 'Sorry, het extraheren van audio uit te grote video\'s wordt niet ondersteund.'
    },
    downloadThu: {
        thumbnail: 'Thumbnail',
        download: 'DOWNLOADEN',
        wait_title: 'Thumbnail downloaden',
        wait_down1: 'het kan een paar ogenblikken duren...',
        wait_down2: 'Wacht alstublieft terwijl het bestand wordt voorbereid voor downloaden...',
        success_title: 'Downloaden succesvol',
        success_cont: 'Controleer alstublieft de map.',
    },
    downloadSubTit: {
        subtitle: 'Ondertitel',
        languages: 'Talen',
        noSubTit: 'Sorry, er zijn geen ondertitels beschikbaar voor deze video.',
        onlyCC: 'Alleen video met cc kan worden gedownload. In de video ingebedde ondertitels worden niet ondersteund.',
        wait_title: 'Ondertitel downloaden',
        wait_down1: 'het kan een paar ogenblikken duren...',
        wait_down2: 'Wacht alstublieft terwijl het bestand wordt voorbereid voor downloaden...',
        success_title: 'Downloaden succesvol',
        success_cont: 'Controleer alstublieft de map.',
    },
    tubePaw: {
        learnMore: 'Meer leren >',
        productInfo: 'Download video\'s/audio/ondertitels van meer dan 10000 websites en converteer naar MP4/WebM/MP3/WAV van hoge kwaliteit.',
        tubePawInfo: 'Beste Video&Music Downloader voor Android die meer dan 10000 websites ondersteunt, waarbij mediabestanden worden opgeslagen als MP4/MP3/WAV van hoge kwaliteit.'
    },
    hitPawVideoConverterPop: {
        productName: 'TubePaw Downloader voor Desktop',
        productText: 'Download 8K/4K/2K/HD video\'s van YouTube en andere meer dan 10000 populaire sites. TubePaw Downloader voor Desktop is een alles-in-één tool om video/audio/ondertitels/thumbnail in hoge kwaliteit te downloaden, video&audio tussen 1000 formaten te converteren en video/audio te bewerken om uw video-ervaring te verrijken.',
        learnMore: 'Meer leren',
        tryItFree: 'PROBEER HET GRATIS',
        online: 'Online',
        desktop: 'Desktop',
        td1: 'Download 8K/4K/2K/HD video\'s',
        td2: 'Ondersteunt meer platforms zoals Dailymotion, Bandcamp, Mixcloud enz.',
        td3: 'Download video\'s in bulk',
        td4: 'Download afspeellijsten en kanalen',
        td5: 'GPU versnelling',
        td6: 'Videobewerkingsfuncties',
        td7: 'Converteren tussen 1000 formaten',
        td8: 'Batchconversie',
        td9: 'Sterke stabiliteit',
        td10: 'Geen bestandslimiet',
        proposalPop: "Vanwege de beleidsbeperkingen van YouTube zal het downloaden van video's onder 720p resulteren in stille video's (zonder audio). Onze suggesties zijn als volgt:",
        proposal_1: "1. Gebruik Download4 om de video en audio apart te downloaden en combineer ze vervolgens.",
        proposal_2: "2. Gebruik ons desktopproduct, TubePaw Downloader, om video's met zowel audio als video te downloaden.",
        downloadOnline_txt: "Doorgaan met online downloaden",
        downloadDesktop_txt: "Download Desktop Product",
    },
    hitPawTubePawAppPop: {
        productName: 'Video Downloader for Android',
        productText: 'Beste Video&Music Downloader voor Android die meer dan 10000 websites ondersteunt, waarbij mediabestanden worden opgeslagen als MP4/MP3/WAV van hoge kwaliteit.'
    }
}