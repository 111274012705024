export default {
    form: {
        button: 'Mulai sekarang',
        proposal: 'Tempel tautan video Anda di sini, mis. https://www.youtube.com/watch?v=vojBp3s0K8o',
        note: '<strong>Penafian</strong> :&nbsp; Mengonversi & Mengunduh video berhak cipta YouTube melanggar syarat dan ketentuan YouTube. Tolong jangan menyalin konten tanpa izin dari pemilik hak cipta.',
        note_facebook: '{noteTag} Dapatkan kesalahan "Maaf, jenis tautan ini saat ini tidak didukung" ketika mencoba mengunduh video Facebook di PC / Mac Anda? Silakan klik tombol "Unduh" untuk mencoba beberapa kali lagi atau {fbNote} untuk mengetahui cara memperbaikinya.',
        note_name: 'Catatan',
        note_facebookCont: 'Klik disini',
        note_facebookUrl: 'https://www.download4.cc/guide/how-to-download-facebook-videos-on-pc.html',
        terms_before: 'Dengan menggunakan layanan kami, Anda menyetujui {terms}',
        terms_after: 'ketentuan penggunaan kami',
        terms_url: 'https://www.download4.cc/id/ketentuan-penggunaan.html',
        sub_button: "Download Gratis",
        sub_app_button: "Unduh sekarang",
        sub_app_mobile_button: "Pasang sekarang",
        sub_fixed_button: "Instal aplikasi",
        buy_now: "Beli sekarang"
    },
    loadingCont: 'Menganalisa link unduh, silahkan tunggu sebentar...',
    failure: {
        fail_title: 'Maaf, gagal menganalisa link unduh yang bersangkutan',
        notsupport_title: 'Maaf, jenis link ini tidak didukung saat ini',
        tips1: '1. Pertama, klik tombol "UNDUH" untuk mencoba beberapa kali lagi untuk memperbaiki masalah. Atau jika Anda mencoba mengunduh video Facebook menggunakan tautan yang disalin dari tombol "Salin Tautan" di Facebook, silakan kembali ke halaman video Facebook dan temukan video yang ingin Anda unduh, klik dua kali video tersebut, dan salin URL-nya secara langsung ke download4.cc untuk mendownload video lagi.',
        tips2: '2. Kedua, pastikan bahwa video / music link dapat diakses dan diputar secara normal.',
        tips3: '3. Ketiga, tautan unduhan mungkin tidak dianalisis karena jaringan yang tidak stabil. Periksa jaringan Anda atau alihkan ke jaringan yang lebih stabil dan coba lagi beberapa kali. Jika Anda masih tidak dapat menganalisis setelah beberapa kali mencoba, klik "{feedbackTag}" di prompt gagal dan kami akan memperbaikinya untuk membantu Anda sesegera mungkin.',
        tips4: '4. Beberapa video memerlukan software untuk mengunduh. Setelah menginstall software, Anda bisa mengunduh semua video online tanpa batas.{subscribeTag}',
        feedback_name: 'Umpan Balik',
        feedback_success_cont: 'Feedback success!',
        subscribe_name: 'Belajarlah lagi>'
    },
    subscribe: {
        title: 'Perangkat lunaknya akan segera keluar, Anda dapat berlangganan sekarang. Setelah berlangganan, kami akan mengirimkan software download video / musik gratis kepada Anda secepatnya!',
        button: 'Berlangganan sekarang',
        proposal: 'Please enter email address',
        subscribe_success_cont: 'Subscribe success!',
        subscribe_fail_cont: 'Invalid email format',
    },
    downloadCont: {
        durationPre: 'Durasi',
        tips_title: 'Tips unduh:',
        tips1: '<strong>Untuk Komputer: </strong>Klik kanan tombol unduh dan pilih “Simpan Link Sebagai” untuk mengunduh;',
        tips2: '<strong>Untuk Android: </strong>Tekan lama tombol unduh, pilih “Simpan Link Sebagai” untuk mengunduh;',
        tips3: '<strong>Untuk iOS: </strong>Gunakan browser built-in dari Aplikasi Documents untuk mengunduh di situs ini.'
    },
    downloadOther: {
        video: 'Video',
        audio: 'Audio',
        audio_and_video: 'Video & Audio',
        silent_video: 'Silent Video',
        quality: 'Kualitas',
        format: 'Format',
        size: 'Ukuran',
        download: 'Unduh',
        note: '{noteTag}: Tidak dapat menemukan format yang Anda butuhkan? Klik format apa pun untuk mengunduh, lalu gunakan {hitpawTag} untuk mengonversinya ke format yang Anda butuhkan.',
        note_name: 'Catatan',
        hitpaw_url: 'https://www.vcows.net/purchase/buy-vcows.html',
        hfv_converter: 'Pengunduh Video untuk Desktop',
    },
    downloadMp3: {
        wait_title: 'Mendownload mp3',
        wait_down1: 'Mungkin perlu beberapa saat...',
        wait_down2: 'Harap tunggu sementara file sedang disiapkan untuk diunduh...',
        success_title: 'Download berhasil',
        success_cont: 'Silakan periksa foldernya.',
        fail_cont: 'Analisis gagal! Harap gunakan Video Downloader untuk Desktop untuk mencoba lagi.',
        too_large: 'Maaf, mengekstrak audio dari video yang terlalu besar tidak didukung.'
    },
    downloadThu: {
        thumbnail: 'Gambar kecil',
        download: 'UNDUH',
        wait_title: 'Mendownload thumbnail',
        wait_down1: 'Mungkin perlu beberapa saat...',
        wait_down2: 'Harap tunggu sementara file sedang disiapkan untuk diunduh...',
        success_title: 'Download berhasil',
        success_cont: 'Silakan periksa foldernya.',
    },
    downloadSubTit: {
        subtitle: 'Subtitle',
        languages: 'Bahasa',
        noSubTit: 'Maaf, tidak ada subtitle yang tersedia untuk video ini.',
        onlyCC: 'Hanya video yang memiliki cc yang dapat diunduh. Subtitle yang disematkan dalam video tidak didukung.',
        wait_title: 'Mendownload subtitle',
        wait_down1: 'Mungkin perlu beberapa saat...',
        wait_down2: 'Harap tunggu sementara file sedang disiapkan untuk diunduh...',
        success_title: 'Download berhasil',
        success_cont: 'Silakan periksa foldernya.',
    },
    tubePaw: {
        learnMore: 'BELAJARLAH LAGI',
        productInfo: 'Mengunduh video/audio/subtitel dari 10.000+ situs web dan mengonversinya ke MP4/WebM/MP3/WAV dalam kualitas tinggi.',
        tubePawInfo: 'Pengunduh Video&Musik terbaik untuk Android yang mendukung 10.000+ situs web, menyimpan file media sebagai format MP4/MP3/WAV kualitas tinggi.'
    },
    hitPawVideoConverterPop: {
        productName: 'Video Downloader for Desktop',
        productText: 'Download video 8K/4K/2K/HD dari YouTube dan 10.000+ situs populer lainnya. Video Downloader for Desktop adalah alat lengkap untuk download video/audio/subtitle/thumbnail dalam kualitas tinggi, mengonversi video&audio antara 1000 format, dan mengedit video/audio untuk memperkaya pengalaman video Anda.',
        learnMore: 'BELAJARLAH LAGI',
        tryItFree: 'COBA GRATIS',
        online: 'On line',
        desktop: 'Komputer',
        td1: 'Unduh video 8K/4K/2K/HD',
        td2: 'Mendukung lebih banyak platform seperti Dailymotion, Bandcamp, Mixcloud dll.',
        td3: 'Unduh video secara massal',
        td4: 'Download daftar putar dan saluran',
        td5: 'Akselerasi GPU',
        td6: 'Fitur pengeditan video',
        td7: 'Mengkonversi antara 1000 format',
        td8: 'Konversi batch',
        td9: 'Stabilitas yang kuat',
        td10: 'Tidak ada batasan ukuran file',
        proposalPop: "Karena pembatasan kebijakan YouTube, mengunduh video di bawah 720p secara daring akan menghasilkan video yang senyap (tanpa audio). Saran kami adalah sebagai berikut:",
        proposal_1: "1. Gunakan Download4 untuk mengunduh video dan audio secara terpisah, lalu gabungkan.",
        proposal_2: "2. Gunakan produk desktop kami, TubePaw Downloader, untuk mengunduh video dengan audio dan video yang digabungkan.",
        downloadOnline_txt: "Lanjutkan unduhan online",
        downloadDesktop_txt: "Unduh Produk Desktop",
    },
    hitPawTubePawAppPop: {
        productName: 'Video Downloader for Android',
        productText: 'Pengunduh Video&Musik terbaik untuk Android yang mendukung 10.000+ situs web, menyimpan file media sebagai format MP4/MP3/WAV kualitas tinggi.',
        learnMore: 'BELAJARLAH LAGI'
    }
}