import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from './en'
import es from './es'
import pt from './pt'
import vi from './vi'
import id from './id'
import jp from './jp'
import it from './it'
import ar from './ar'
import zhTw from './zh-tw'
import de from './de'
import fr from './fr'
import inl from './in'
import ru from './ru'
import th from './th'
import nl from './nl'

Vue.use(VueI18n)

export default new VueI18n({
  locale: document.body.getAttribute('data-lang') || 'en',
  messages: {
    'en': en,
    'es': es,
    'pt': pt,
    'vi': vi,
    'id': id,
    'jp': jp,
    'it': it,
    'ar': ar,
    'zh-tw': zhTw,
    'de':de,
    'fr':fr,
    'in':inl,
    'ru':ru,
    'th':th,
    'nl':nl,
  }
})