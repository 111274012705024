export default {
    form: {
        button: 'Bắt đầu bây giờ',
        proposal: 'Dán liên kết video của bạn vào đây, ví dụ: https://www.youtube.com/watch?v=vojBp3s0K8o',
        note: '<strong>Tuyên bố từ chối trách nhiệm</strong> :&nbsp;  Việc chuyển đổi và tải xuống các video có bản quyền trên YouTube vi phạm các điều khoản và điều kiện của YouTube. Vui lòng không sao chép nội dung mà không có sự cho phép của chủ sở hữu bản quyền.',
        note_facebook: '{noteTag} Gặp lỗi “Rất tiếc, loại liên kết này hiện không được hỗ trợ” khi cố tải xuống video Facebook trên PC / Mac của bạn? Vui lòng nhấp vào nút "Tải xuống" để thử lại nhiều lần hoặc {fbNote} để biết cách khắc phục nó.',
        note_name: 'Lưu ý',
        note_facebookCont: 'Bấm vào đây',
        note_facebookUrl: 'https://www.download4.cc/guide/how-to-download-facebook-videos-on-pc.html',
        terms_before: 'Bằng cách sử dụng dịch vụ của chúng tôi, bạn chấp nhận các {terms} của chúng tôi',
        terms_after: 'điều khoản sử dụng',
        terms_url: 'https://www.download4.cc/vi/terms-of-use.html',
        sub_button: 'Tải xuống miễn phí',
        sub_app_button: 'Tải ngay',
        sub_app_mobile_button: "Cài đặt ngay",
        sub_fixed_button: "Cài đặt ứng dụng",
        buy_now: "Mua ngay"
    },
    loadingCont: 'Đang phân tích liên kết tải, xin hãy đợi trong giây lát...',
    failure: {
        fail_title: 'Xin lỗi, không phân tích được liên kết tải tương ứng',
        notsupport_title: 'Xin lỗi, loại liên kết này hiện không được hỗ trợ',
        tips1: '1. Trước tiên, vui lòng nhấp vào nút “TẢI XUỐNG” để thử lại nhiều lần để khắc phục sự cố. Hoặc nếu bạn đang cố tải xuống video Facebook bằng liên kết được sao chép từ nút "Sao chép liên kết" trong Facebook, hãy quay lại trang video Facebook và tìm video bạn muốn tải xuống, nhấp đúp vào video và sao chép trực tiếp URL của nó để download4.cc để tải lại video.',
        tips2: '2. Thứ hai, đảm bảo rằng liên kết video / nhạc có thể được truy cập và phát bình thường.',
        tips3: '3. Thứ ba, có thể không phân tích được link tải do mạng không ổn định. Vui lòng kiểm tra mạng của bạn hoặc chuyển sang mạng ổn định hơn và thử lại vài lần. Nếu bạn vẫn không thể phân tích sau nhiều lần thử, vui lòng nhấp vào "{feedbackTag}" trong phần thông báo lỗi và chúng tôi sẽ khắc phục để hỗ trợ trong thời gian sớm nhất.',
        tips4: '4. Một số video yêu cầu phần mềm để tải xuống. Sau khi cài đặt phần mềm, bạn có thể tải tất cả các video trực tuyến mà không có bất kỳ giới hạn nào.{subscribeTag}',
        feedback_name: 'Phản hồi',
        feedback_success_cont: 'Feedback success!',
        subscribe_name: 'Tìm hiểu thêm>'
    },
    subscribe: {
        title: 'Phần mềm sẽ sớm ra mắt, bạn có thể đăng ký ngay bây giờ. Sau khi đăng ký, chúng tôi sẽ gửi phần mềm tải video/nhạc miễn phí cho bạn trong thời gian sớm nhất!',
        button: 'Theo dõi ngay',
        proposal: 'Please enter email address',
        subscribe_success_cont: 'Subscribe success!',
        subscribe_fail_cont: 'Invalid email format',
    },
    downloadCont: {
        durationPre: 'Thời lượng',
        tips_title: 'Mẹo tải xuống:',
        tips1: '<strong>Đối với Máy tính: </strong>Nhấp chuột phải vào nút tải xuống và chọn "Lưu Liên kết Dưới dạng" để tải xuống;',
        tips2: '<strong>Với Android: </strong>Nhấn và giữ nút tải xuống, chọn "Lưu Liên kết Dưới dạng" để tải xuống;',
        tips3: '<strong>Với iOS: </strong>Sử dụng trình duyệt cài sẵn của Ứng dụng Tài liệu để tải từ trang web này.'
    },
    downloadOther: {
        video: 'Video',
        audio: 'Âm thanh',
        audio_and_video: 'Vídeo & Âm thanh',
        silent_video: 'Video im lặng',
        quality: 'Chất lượng',
        format: 'Định dạng',
        size: 'Kích thước',
        download: 'Tải xuống',
        note: '{noteTag}: Không thể tìm thấy các định dạng bạn cần? Nhấp vào bất kỳ định dạng nào để tải xuống, sau đó, sử dụng {hitpawTag} để chuyển đổi nó sang định dạng bạn cần.',
        note_name: 'Ghi chú',
        hitpaw_url: 'https://www.vcows.net/purchase/buy-vcows.html',
        hfv_converter: 'Trình tải xuống video cho máy tính để bàn',
    },
    downloadMp3: {
        wait_title: 'Đang tải xuống mp3',
        wait_down1: 'Có thể mất vài phút...',
        wait_down2: 'Vui lòng đợi trong khi tệp đang được chuẩn bị để tải xuống...',
        success_title: 'Tải xuống thành công',
        success_cont: 'Vui lòng kiểm tra thư mục.',
        fail_cont: 'Không phân tích được! Vui lòng sử dụng Trình tải xuống Video dành cho Máy tính để bàn để thử lại.',
        too_large: 'Rất tiếc, không hỗ trợ trích xuất âm thanh từ video quá lớn.'
    },
    downloadThu: {
        thumbnail: 'Hình nhỏ',
        download: 'Tải xuống',
        wait_title: 'Đang tải xuống hình thu nhỏ',
        wait_down1: 'Có thể mất vài phút...',
        wait_down2: 'Vui lòng đợi trong khi tệp đang được chuẩn bị để tải xuống...',
        success_title: 'Tải xuống thành công',
        success_cont: 'Vui lòng kiểm tra thư mục.',
    },
    downloadSubTit: {
        subtitle: 'Phụ đề',
        languages: 'Ngôn ngữ',
        noSubTit: 'Xin lỗi, không có phụ đề cho video này.',
        onlyCC: 'Chỉ video có cc mới có thể được tải xuống. Phụ đề nhúng trong video không được hỗ trợ.',
        wait_title: 'Đang tải xuống phụ đề',
        wait_down1: 'Có thể mất vài phút...',
        wait_down2: 'Vui lòng đợi trong khi tệp đang được chuẩn bị để tải xuống...',
        success_title: 'Tải xuống thành công',
        success_cont: 'Vui lòng kiểm tra thư mục.',
    },
    tubePaw: {
        learnMore: 'TÌM HIỂU THÊM',
        productInfo: 'Tải xuống video / âm thanh / phụ đề từ hơn 10000 trang web và chuyển đổi sang MP4 / WebM / MP3 / WAV với chất lượng cao.',
        tubePawInfo: 'Trình tải xuống Video & Nhạc tốt nhất dành cho Android hỗ trợ hơn 10000 trang web, lưu tệp phương tiện dưới định dạng MP4/MP3/WAV chất lượng cao.'
    },
    hitPawVideoConverterPop: {
        productName: 'Video Downloader for Desktop',
        productText: 'Tải xuống video 8K / 4K / 2K / HD từ YouTube và hơn 10000 trang web phổ biến khác. Video Downloader for Desktop là một công cụ tất cả trong một để tải xuống video / âm thanh / phụ đề / hình thu nhỏ với chất lượng cao, chuyển đổi video và âm thanh giữa 1000 định dạng và chỉnh sửa video / âm thanh để làm phong phú trải nghiệm video của bạn.',
        learnMore: 'TÌM HIỂU THÊM',
        tryItFree: 'DÙNG THỬ MIỄN PHÍ',
        online: 'Trực tuyến',
        desktop: 'Máy vi tính',
        td1: 'Tải xuống video 8K / 4K / 2K / HD',
        td2: 'Hỗ trợ nhiều nền tảng hơn như Dailymotion, Bandcamp, Mixcloud, v.v.',
        td3: 'Tải xuống hàng loạt video',
        td4: "Tải xuống danh sách phát và các kênh",
        td5: 'Tăng tốc GPU',
        td6: 'Các tính năng chỉnh sửa video',
        td7: 'Chuyển đổi giữa 1000 định dạng',
        td8: 'Chuyển đổi hàng loạt',
        td9: 'Ổn định mạnh mẽ',
        td10: 'Không có giới hạn kích thước tệp',
        proposalPop: "Do các hạn chế về chính sách của YouTube, việc tải xuống video trực tuyến dưới 720p sẽ dẫn đến video bị im lặng (không có âm thanh). Đề xuất của chúng tôi như sau:",
        proposal_1: "1. Sử dụng Download4 để tải xuống video và âm thanh riêng biệt, sau đó hợp nhất chúng.",
        proposal_2: "2. Sử dụng sản phẩm máy tính để bàn của chúng tôi, TubePaw Downloader, để tải xuống video có cả âm thanh và video kết hợp.",
        downloadOnline_txt: "Tiếp tục tải xuống trực tuyến",
        downloadDesktop_txt: "Tải desktop",
    },
    hitPawTubePawAppPop: {
        productName: 'Video Downloader for Android',
        productText: 'Trình tải xuống Video & Nhạc tốt nhất dành cho Android hỗ trợ hơn 10000 trang web, lưu tệp phương tiện dưới định dạng MP4/MP3/WAV chất lượng cao.',
        learnMore: 'TÌM HIỂU THÊM'
    }
}