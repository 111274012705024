export default {
    form: {
        button: '今すぐ開始',
        proposal: 'ここで動画のリンクを貼り付けます。例えば：https://www.youtube.com/watch?v=vojBp3s0K8o',
        note: '<strong>免責事項</strong>：&nbsp; YouTubeの著作権で保護された動画を変換＆ダウンロードすることは、YouTubeの規約に違反しています。著作権所有者の許可なくコンテンツをコピーすることはおやめください。',
        note_facebook: '{noteTag}：PC / MacにFacebookビデオをダウンロードしようとすると、「申し訳ありませんが、このタイプのリンクは現在サポートされていません」というエラーが表示されます。[ダウンロード]ボタンをクリックして数回再試行するか、{fbNote}して修正方法を確認してください。',
        note_name: '注',
        note_facebookCont: 'ここをクリック',
        note_facebookUrl: 'https://www.download4.cc/guide/how-to-download-facebook-videos-on-pc.html',
        terms_before: '当社のサービスを利用することにより、お客様は当社の {terms}',
        terms_after: '利用規約に',
        terms_url: 'https://www.download4.cc/jp/terms-of-use.html',
        sub_button: '無料ダウンロード',
        sub_app_button: '今すぐダウンロード',
        sub_app_mobile_button: "今すぐインストール",
        sub_fixed_button: "Appをインストール",
        buy_now: "今すぐ購入"
    },
    loadingCont: 'ダウンロードリンクを分析して、しばらくお待ちください...',
    failure: {
        fail_title: '申し訳ありませんが、対応するダウンロードリンクをダウンロードできませんでした',
        notsupport_title: '申し訳ありませんが、このタイプのリンクは現在サポートされていません',
        tips1: '1.まず、「ダウンロード」ボタンをクリックして、問題を解決するために何度か再試行してください。または、Facebookの「リンクのコピー」ボタンからコピーしたリンクを使用してFacebookビデオをダウンロードしようとしている場合は、Facebookに戻ってください。ビデオページでダウンロードしたいビデオを見つけ、ビデオをダブルクリックし、そのURLをdownload4.ccに直接コピーして、ビデオを再度ダウンロードします。',
        tips2: '2.次に、ビデオ/音楽リンクにアクセスして正常に再生できることを確認します。',
        tips3: '3.第三に、ネットワークが不安定なため、ダウンロードリンクが分析されない場合があります。ネットワークを確認するか、より安定したネットワークに切り替えて、何度か再試行してください。何度も試行しても分析できない場合は、の[{feedbackTag}]をクリックしてください。失敗のプロンプトと私たちはできるだけ早くサポートするために修正します。',
        tips4: '4.一部のビデオでは、ソフトウェアのダウンロードが必要です。ソフトウェアのインストール後、すべてのオンラインビデオを無制限にダウンロードできます。{subscribeTag}',
        feedback_name: 'フィードバック',
        feedback_success_cont: 'フィードバックの成功',
        subscribe_name: 'もっと詳しく知る>'
    },
    subscribe: {
        title: 'ソフトウェアはまもなくリリースされますので、今すぐ登録してください。登録後、無料のビデオ/音楽ダウンロードソフトウェアをできるだけ早くお送りします。',
        button: '今すぐ購読',
        proposal: 'メールアドレスを入力してください',
        subscribe_success_cont: '購読成功！',
        subscribe_fail_cont: '無効なメール形式',
    },
    downloadCont: {
        durationPre: '間隔',
        tips_title: 'ダウンロードのヒント:',
        tips1: '<strong>コンピューターの場合：</strong>ダウンロードボタンを右クリックし、[名前を付けてリンクを保存]を選択してダウンロードします。',
        tips2: '<strong>Androidの場合：</strong>ダウンロードボタンを長押しし、[名前を付けてリンクを保存]を選択してダウンロードします。',
        tips3: '<strong>iOSの場合：</strong>ドキュメントアプリの組み込みブラウザを使用して、このサイトからダウンロードします。'
    },
    downloadOther: {
        video: 'ビデオ',
        audio: 'オーディオ',
        audio_and_video: 'ビデオとオーディオ',
        silent_video: 'サイレントビデオ',
        quality: '品質',
        format: 'フォーマット',
        size: 'サイズ',
        download: 'ダウンロード',
        note: '{noteTag}：必要な形式が見つかりませんか？ダウンロードする形式をクリックしてから、{hitpawTag} を使用して必要な形式に変換してください。',
        note_name: '注',
        hitpaw_url: 'https://www.vcows.net/purchase/buy-vcows.html',
        hfv_converter: 'デスクトップ用ビデオダウンローダー',
    },
    downloadMp3: {
        wait_title: 'mp3をダウンロードする',
        wait_down1: '少し時間がかかるかもしれません…',
        wait_down2: 'ファイルのダウンロードの準備が整うまでお待ちください…',
        success_title: '正常にダウンロード',
        success_cont: 'フォルダを確認してください。',
        fail_cont: '解析に失敗しました。デスクトップ動画ダウンローダーをダウンロードして、もう一度やり直してください。',
        too_large: '申し訳ありませんが、大きすぎる動画からの音声の抽出はサポートされていません。'
    },
    downloadThu: {
        thumbnail: 'サムネイル',
        download: 'ダウンロード',
        wait_title: 'サムネイルのダウンロード',
        wait_down1: '少し時間がかかるかもしれません…',
        wait_down2: 'ファイルのダウンロードの準備が整うまでお待ちください…',
        success_title: '正常にダウンロード',
        success_cont: 'フォルダを確認してください。',
    },
    downloadSubTit: {
        subtitle: '字幕',
        languages: '言語',
        noSubTit: '申し訳ありませんが、この動画に使用できる字幕はありません。',
        onlyCC: 'ダウンロードできるのはcc付きの動画のみです。 ビデオに埋め込まれた字幕はサポートされていません。',
        wait_title: '字幕をダウンロードする',
        wait_down1: '少し時間がかかるかもしれません…',
        wait_down2: 'ファイルのダウンロードの準備が整うまでお待ちください…',
        success_title: '正常にダウンロード',
        success_cont: 'フォルダを確認してください。',
    },
    tubePaw: {
        learnMore: 'もっと詳しく',
        productInfo: '10000以上のウェブサイトからビデオ/オーディオ/字幕をダウンロードし、高画質でMP4/WebM/MP3/WAVに変換する。',
        tubePawInfo: '10000以上のウェブサイトをサポートし、メディアファイルを高品質のMP4/MP3/WAV形式で保存するAndroid用ベスト動画＆音楽ダウンロードソフトです。'
    },
    hitPawVideoConverterPop: {
        productName: 'Video Downloader for Desktop',
        productText: 'YouTube及び10000以上の人気Webサイトから8K/4K/2K/HD動画をダウンロードすることができます。デスクトップ動画ダウンローダーは、高品質のビデオ/オーディオ/字幕/サムネイルをダウンロードし、1000以上のフォーマットでビデオとオーディオの変換、編集ができ、動画体験をさらに豊かにするオールインワンツールです。',
        learnMore: 'もっと詳しく',
        tryItFree: '無料ダウンロード',
        online: 'オンライン',
        desktop: 'デスクトップ',
        td1: '8K/4K/2K/HD動画をダウンロード',
        td2: 'Dailymotion、Bandcamp、Mixcloudなど、より多くのプラットフォームへの対応。',
        td3: '動画の一括ダウンロード',
        td4: 'GPUアクセラレーション',
        td5: '動画編集機能',
        td6: '1000種類のフォーマット間で動画を変換',
        td7: '一括変換',
        td8: '優れた安定性',
        td9: 'ファイルサイズ制限なし',
        proposalPop: "YouTubeのポリシーの制限により、オンラインで720p以下の動画をダウンロードすると、無音動画（音声なし）になります。提案は以下の通りです：",
        proposal_1: "1.Download4を使用してビデオと音声を別々にダウンロードし、その後それらを統合します；",
        proposal_2: "2. デスクトップ製品「TubePaw Downloader」を使えば、音声と動画が一緒になった動画をダウンロードできます；",
        downloadOnline_txt: "オンラインでダウンロードを続ける",
        downloadDesktop_txt: "デスクトップ製品ダウンロード",
    },
    hitPawTubePawAppPop: {
        productName: 'Video Downloader for Android',
        productText: '10000以上のウェブサイトをサポートし、メディアファイルを高品質のMP4/MP3/WAV形式で保存するAndroid用ベスト動画＆音楽ダウンロードソフトです。',
        learnMore: 'もっと詳し'
    }
}