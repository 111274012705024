export default {
    form: {
        button: 'ابدأ الآن',
        proposal: 'الصق رابط الفيديو هنا ، على سبيل المثال https://www.youtube.com/watch?v=vojBp3s0K8o',
        note: '<strong>إخلاء المسؤولية</strong> :&nbsp;  تحويل وتنزيل مقاطع الفيديو المحمية بحقوق الطبع والنشر من يوتيوب ينتهك شروط وأحكام يوتيوب. من فضلك لا تنسخ المحتوى دون إذن من صاحب حقوق التأليف والنشر.',
        note_facebook: '{noteTag} احصل على الخطأ "عذرًا ، هذا النوع من الارتباط غير مدعوم حاليًا" عند محاولة تنزيل فيديو Facebook على جهاز الكمبيوتر / جهاز Mac؟ الرجاء النقر فوق الزر "تنزيل" لإعادة المحاولة عدة مرات أو {fbNote} لمعرفة كيفية إصلاحه.',
        note_name: 'ملاحظة',
        note_facebookCont: 'انقر هنا',
        note_facebookUrl: 'https://www.download4.cc/guide/how-to-download-facebook-videos-on-pc.html',
        terms_before: 'باستخدام خدمتنا ، فإنك تقبل {terms}',
        terms_after: 'شروط الاستخدام الخاصة بنا',
        terms_url: 'https://www.download4.cc/ar/terms-of-use.html',
        sub_button: 'تجربة مجانية',
        sub_app_button: 'تنزيل الآن',
        sub_app_mobile_button: "تثبيت الآن",
        sub_fixed_button: "تثبيت التطبيق",
        buy_now: 'اشتري الآن'
    },
    loadingCont: 'جاري تحليل رابط التنزيل ، يرجى الانتظار قليلاً ...',
    failure: {
        fail_title: 'عذرا ، فشل تحليل رابط التحميل المقابل',
        notsupport_title: 'عذرا ، هذا النوع من الارتباط غير مدعوم حاليا',
        tips1: '1. أولاً ، الرجاء النقر فوق الزر "تنزيل" لإعادة المحاولة عدة مرات لإصلاح المشكلة. أو إذا كنت تحاول تنزيل مقاطع فيديو Facebook باستخدام الرابط المنسوخ من زر "نسخ الرابط" في Facebook ، فيرجى العودة إلى صفحة فيديو Facebook والعثور على الفيديو الذي تريد تنزيله ، والنقر مرتين على الفيديو ، ونسخ عنوان URL الخاص به مباشرة to download4.cc لتنزيل الفيديو مرة أخرى.',
        tips2: '2. ثانيًا ، تأكد من إمكانية الوصول إلى رابط الفيديو / الموسيقى وتشغيله بشكل طبيعي.',
        tips3: '3. ثالثًا، قد لا يتم تحليل الرابط بسبب اضطراب شبكة الانترنت.يرجى التحقق مما إذا كانت شبكتك تعمل بشكل جيد أو قم بالتبديل إلى شبكة أفضل وحاول عدة مرات مرة أخرى.. إذا كنت لا تزال غير قادر على التحليل بعد عدة محاولات ، فالرجاء النقر فوق "استجابة" في موجه الفشل وسنقوم بالإصلاح لتقديم الدعم في أقرب وقت ممكن.',
        tips4: '4. تتطلب بعض مقاطع الفيديو تنزيل برامج. بعد تثبيت البرنامج ، يمكنك تنزيل جميع مقاطع الفيديو عبر الإنترنت دون أي حدود{subscribeTag}',
        feedback_name: 'استجابة',
        feedback_success_cont: 'نجاح ردود الفعل',
        subscribe_name: 'يتعلم أكثر'
    },
    subscribe: {
        title: 'سيصدر البرنامج قريبا ، يمكنك الاشتراك الآن. بعد الاشتراك ، سنرسل لك برنامج تنزيل الفيديو / الموسيقى المجاني في أسرع وقت ممكن!',
        button: 'إشترك الآن',
        proposal: 'الرجاء إدخال عنوان البريد الإلكتروني',
        subscribe_success_cont: 'تم الاشتراك بنجاح!',
        subscribe_fail_cont: 'تنسيق بريد إلكتروني غير صالح',
    },
    downloadCont: {
        durationPre: 'مدة',
        tips_title: 'تنزيل النصائح',
        tips1: '<strong>بالنسبة للكمبيوتر: </strong> انقر بزر الماوس الأيمن فوق زر التنزيل وحدد "حفظ الارتباط باسم" للتنزيل ؛',
        tips2: '<strong>لنظام التشغيل Android: </strong>اضغط لفترة طويلة على زر التنزيل ، وحدد "حفظ الرابط باسم" للتنزيل ؛',
        tips3: '<strong>بالنسبة لنظام التشغيل iOS: </strong>استخدم المستعرض المضمن لتطبيق المستندات للتنزيل على هذا الموقع ؛'
    },
    downloadOther: {
        video: 'فيديو',
        audio: 'صوتي',
        audio_and_video: 'فيديو سمعي',
        silent_video: 'فيديو صامت',
        quality: 'جودة',
        format: 'صيغة',
        size: 'مقاس',
        download: 'تحميل',
        note: '{noteTag}: ملاحظة: لا يمكنك العثور على التنسيقات التي تحتاجها؟ انقر فوق أي تنسيق للتنزيل ، ثم استخدم {hitpawTag}  لتحويله إلى التنسيق الذي تحتاجه.',
        note_name: 'ملحوظة',
        hitpaw_url: 'https://www.vcows.net/purchase/buy-vcows.html',
        hfv_converter: 'برنامج تنزيل الفيديو لسطح المكتب',
    },
    downloadMp3: {
        wait_title: 'Mp3 تحميل',
        wait_down1: 'قد يستغرق بعض الوقت...',
        wait_down2: 'جاري التحضير لتنزيل الملف ، برجاء الانتظار ...',
        success_title: 'تم التنزيل بنجاح',
        success_cont: 'يرجى التحقق من المجلد.',
        fail_cont: 'فشل التحليل! الرجاء استخدام أداة تنزيل الفيديو لسطح المكتب للمحاولة مرة أخرى.',
        too_large: 'عذرًا ، لا يتم دعم استخراج الصوت من مقاطع الفيديو الكبيرة جدًا.'
    },
    downloadThu: {
        thumbnail: 'ظفري',
        download: 'تحميل',
        wait_title: 'تحميل الصور المصغرة',
        wait_down1: 'قد يستغرق بعض الوقت...',
        wait_down2: 'جاري التحضير لتنزيل الملف ، برجاء الانتظار ...',
        success_title: 'تم التنزيل بنجاح',
        success_cont: 'يرجى التحقق من المجلد.',
    },
    downloadSubTit: {
        subtitle: 'العنوان الفرعي',
        languages: 'اللغات',
        noSubTit: 'عذرا ، لا توجد ترجمات متاحة لهذا الفيديو.',
        onlyCC: 'يمكن تنزيل مقاطع الفيديو التي تحتوي على نسخة فقط. لا يتم دعم الترجمات المضمنة في مقاطع الفيديو.',
        wait_title: 'تحميل الترجمات',
        wait_down1: 'قد يستغرق بعض الوقت...',
        wait_down2: 'جاري التحضير لتنزيل الملف ، برجاء الانتظار ...',
        success_title: 'تم التنزيل بنجاح',
        success_cont: 'يرجى التحقق من المجلد.',
    },
    tubePaw: {
        learnMore: 'اعرف المزيد',
        productInfo: 'يقوم بتنزيل مقاطع الفيديو / الصوت / الترجمة من 10000+ موقع ويب وتحويلها إلى MP4 / WebM / MP3 / WAV بجودة عالية.',
        tubePawInfo: 'أفضل برنامج تحميل الفيديو والأغاني لأندرويد يدعم أكثر من 10000 موقع ويب ، ويحفظ ملفات الوسائط بتنسيقات MP4 / MP3 / WAV عالية الجودة.'
    },
    hitPawVideoConverterPop: {
        productName: 'Video Downloader for Desktop',
        productText: 'م بتنزيل مقاطع فيديو 8K / 4K / 2K / HD من YouTube و 10000+ موقع شهير. يعد تنزيل الفيديو لسطح المكتب أداة شاملة لتنزيل الفيديو / الصوت / العنوان الفرعي / الصورة المصغرة بجودة عالية ، وتحويل الفيديو والصوت بين 1000 تنسيق ، وتحرير الفيديو / الصوت لإثراء تجربة الفيديو الخاصة بك.',
        learnMore: 'اعرف المزيد',
        tryItFree: 'تجربة مجانية',
        online: 'متصل',
        desktop: 'سطح المكتب',
        td1: 'تحميل مقاطع فيديو 8K / 4K / 2K / HD',
        td2: 'يدعم المزيد من المنصات مثل Dailymotion و Bandcamp و Mixcloud وما إلى ذلك.',
        td3: 'تحميل الفيديوهات على دفعات',
        td4: 'قم بتنزيل قائمة التشغيل والقنوات',
        td5: 'تسريع GPU',
        td6: 'ميزات تحرير الفيديو',
        td7: 'تحويل بين 1000 صيغة',
        td8: 'تحويل الدفعات',
        td9: 'وظيفة مستقرة',
        td10: 'لا يوجد حد لحجم الملف',
        proposalPop: "بسبب قيود سياسات YouTube، فإن تنزيل مقاطع الفيديو بدقة أقل من 720p عبر الإنترنت سيؤدي إلى مقاطع فيديو صامتة (بدون صوت). توصياتنا هي كالتالي:",
        proposal_1: "1. استخدم Download4 لتنزيل الفيديو والصوت بشكل منفصل، ثم دمجهما.",
        proposal_2: "2. استخدم منتجنا لسطح المكتب، TubePaw Downloader، لتنزيل مقاطع الفيديو مع الصوت والفيديو معًا.",
        downloadOnline_txt: "استمر في التنزيل عبر الإنترنت",
        downloadDesktop_txt: "تنزيل منتج سطح المكتب",
    },
    hitPawTubePawAppPop: {
        productName: 'Video Downloader for Android',
        productText: 'أفضل برنامج تحميل الفيديو والأغاني لأندرويد يدعم أكثر من 10000 موقع ويب ، ويحفظ ملفات الوسائط بتنسيقات MP4 / MP3 / WAV عالية الجودة.',
        learnMore: 'اعرف المزيد'
    }
}