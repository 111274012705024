export default {
    form: {
        button: 'เริ่มเลย',
        proposal: 'วางลิงก์วิดีโอของคุณที่นี่ เช่น https://www.youtube.com/watch?v=vojBp3s0K8o',
        note: '<strong>ข้อจำกัดความรับผิดชอบ</strong> :&nbsp; การแปลงและดาวน์โหลดวิดีโอ YouTube ที่มีลิขสิทธิ์ถือเป็นการละเมิดข้อกำหนดและเงื่อนไขของ YouTube โปรดอย่าคัดลอกเนื้อหาโดยไม่ได้รับอนุญาตจากเจ้าของลิขสิทธิ์',
        note_facebook: '{noteTag}ได้รับข้อผิดพลาด "ขออภัย ลิงก์ประเภทนี้ไม่ได้รับการรองรับในขณะนี้" เมื่อพยายามดาวน์โหลดวิดีโอ Facebook บนพีซี/Mac ของคุณ โปรดคลิกปุ่ม "ดาวน์โหลด" เพื่อลองใหม่หลายครั้งหรือ {fbNote} เพื่อดูวิธีแก้ไข',
        note_name: 'Note',
        note_facebookCont: 'คลิกที่นี่',
        note_facebookUrl: 'https://www.download4.cc/guide/how-to-download-facebook-videos-on-pc.html',
        terms_before: 'การใช้บริการของเราแสดงว่าคุณยอมรับ {เงื่อนไข} ของเรา',
        terms_after: 'เงื่อนไขการใช้งาน',
        terms_url: 'https://www.download4.cc/th/terms-of-use.html',
        sub_button: "ดาวน์โหลดฟรี",
        sub_app_button: "ดาวน์โหลดเลย",
        sub_app_mobile_button: "ติดตั้งเลย",
        sub_fixed_button: "ติดตั้งแอป",
        buy_now: 'ซื้อเลย'
    },
    loadingCont: 'กำลังวิเคราะห์ลิงก์ดาวน์โหลด โปรดรอสักครู่...',
    failure: {
        fail_title: 'ขออภัย วิเคราะห์ลิงก์ดาวน์โหลดที่เกี่ยวข้องไม่สำเร็จ',
        notsupport_title: 'ขออภัย ลิงก์ประเภทนี้ไม่ได้รับการรองรับในขณะนี้',
        tips1: '1. ก่อนอื่น โปรดคลิกปุ่ม "ดาวน์โหลด" เพื่อลองใหม่หลายครั้งเพื่อแก้ไขปัญหา หรือหากคุณกำลังพยายามดาวน์โหลดวิดีโอ Facebook โดยใช้ลิงก์ที่คัดลอกมาจากปุ่ม "คัดลอกลิงก์" ใน Facebook โปรดกลับมาที่หน้าวิดีโอ Facebook และค้นหาวิดีโอที่คุณต้องการดาวน์โหลด ดับเบิลคลิกวิดีโอและคัดลอก URL โดยตรงไปยัง download4.cc เพื่อดาวน์โหลดวิดีโออีกครั้ง',
        tips2: '2. ประการที่สอง ตรวจสอบให้แน่ใจว่าสามารถเข้าถึงและเล่นลิงก์วิดีโอ/เพลงได้ตามปกติ',
        tips3: '3. ประการที่สาม ลิงก์ดาวน์โหลดอาจไม่สามารถวิเคราะห์ได้เนื่องจากเครือข่ายไม่เสถียร โปรดตรวจสอบเครือข่ายของคุณหรือเปลี่ยนไปใช้เครือข่ายที่เสถียรกว่าแล้วลองใหม่หลายครั้ง หากคุณยังวิเคราะห์ไม่ได้หลังจากพยายามหลายครั้ง โปรดคลิก "{feedbackTag}" ในข้อความแจ้งข้อผิดพลาด เราจะแก้ไขและให้การสนับสนุนโดยเร็วที่สุด',
        tips4: '4. วิดีโอบางรายการต้องใช้ซอฟต์แวร์ในการดาวน์โหลด หลังจากติดตั้งซอฟต์แวร์แล้ว คุณสามารถดาวน์โหลดวิดีโอออนไลน์ทั้งหมดได้โดยไม่มีข้อจำกัดใดๆ{subscribeTag}',
        feedback_name: 'คำติชม',
        feedback_success_cont: 'คำติชมสำเร็จ!',
        subscribe_name: 'เรียนรู้เพิ่มเติม >'
    },
    subscribe: {
        title: 'ซอฟต์แวร์จะออกมาเร็วๆ นี้ คุณสามารถสมัครสมาชิกได้แล้ว หลังจากสมัครสมาชิกแล้ว เราจะส่งซอฟต์แวร์ดาวน์โหลดวิดีโอ/เพลงฟรีให้คุณโดยเร็วที่สุด!',
        button: 'สมัครสมาชิกทันที',
        proposal: 'กรุณาป้อนที่อยู่อีเมล',
        subscribe_success_cont: 'สมัครสมาชิกสำเร็จ!',
        subscribe_fail_cont: 'รูปแบบอีเมลไม่ถูกต้อง',
    },
    downloadCont: {
        durationPre: 'ระยะเวลา',
        tips_title: 'คำแนะนำในการดาวน์โหลด:',
        tips1: '<strong>สำหรับคอมพิวเตอร์: </strong>คลิกขวาที่ปุ่มดาวน์โหลดและเลือก "บันทึกลิงก์เป็น" เพื่อดาวน์โหลด',
        tips2: '<strong>สำหรับ Android: </strong>กดปุ่มดาวน์โหลดค้างไว้ เลือก "บันทึกลิงก์เป็น" เพื่อดาวน์โหลด',
        tips3: '<strong>สำหรับ iOS: </strong>ใช้เบราว์เซอร์ในตัวของแอป Documents เพื่อดาวน์โหลดจากไซต์นี้'
    },
    downloadOther: {
        video: 'วิดีโอ',
        audio: 'เสียง',
        audio_and_video: 'วิดีโอและเสียง',
        silent_video: 'วิดีโอเงียบ',
        quality: 'คุณภาพ',
        format: 'รูปแบบ',
        size: 'ขนาด',
        download: 'ดาวน์โหลด',
        note: '{noteTag}: ไม่พบรูปแบบที่คุณต้องการ? คลิกรูปแบบใดก็ได้เพื่อดาวน์โหลด จากนั้นใช้ {hitpawTag} เพื่อแปลงเป็นรูปแบบที่คุณต้องการ',
        note_name: 'หมายเหตุ',
        hitpaw_url: 'https://www.vcows.net/purchase/buy-vcows.html',
        hfv_converter: 'โปรแกรมดาวน์โหลดวิดีโอสำหรับเดสก์ท็อป',
    },
    downloadMp3: {
        wait_title: 'กำลังดาวน์โหลด mp3',
        wait_down1: 'อาจใช้เวลาสักครู่...',
        wait_down2: 'โปรดรอสักครู่ขณะที่กำลังเตรียมไฟล์สำหรับการดาวน์โหลด...',
        success_title: 'ดาวน์โหลดสำเร็จ',
        success_cont: 'โปรดตรวจสอบโฟลเดอร์',
        fail_cont: 'การวิเคราะห์ล้มเหลว! โปรดใช้ TubePaw Downloader สำหรับเดสก์ท็อปเพื่อลองอีกครั้ง ',
        too_large: 'ขออภัย การแยกไฟล์เสียงจากวิดีโอที่มีขนาดใหญ่เกินไปไม่ได้รับการรองรับ'
    },
    downloadThu: {
        thumbnail: 'ภาพขนาดย่อ',
        download: 'ดาวน์โหลด',
        wait_title: 'กำลังดาวน์โหลดภาพขนาดย่อ',
        wait_down1: 'อาจใช้เวลาสักครู่...',
        wait_down2: 'โปรดรอสักครู่ขณะที่กำลังเตรียมไฟล์สำหรับการดาวน์โหลด...',
        success_title: 'ดาวน์โหลดสำเร็จ',
        success_cont: 'โปรดตรวจสอบโฟลเดอร์',
    },
    downloadSubTit: {
        subtitle: 'คำบรรยาย',
        languages: 'ภาษา',
        noSubTit: 'ขออภัย ไม่มีคำบรรยายสำหรับวิดีโอนี้',
        onlyCC: 'สามารถดาวน์โหลดได้เฉพาะวิดีโอที่มี cc เท่านั้น ไม่รองรับคำบรรยายที่ฝังอยู่ในวิดีโอ',
        wait_title: 'กำลังดาวน์โหลดคำบรรยาย',
        wait_down1: 'อาจใช้เวลาสักครู่...',
        wait_down2: 'โปรดรอสักครู่ขณะที่กำลังเตรียมไฟล์สำหรับการดาวน์โหลด...',
        success_title: 'ดาวน์โหลดสำเร็จ',
        success_cont: 'โปรดตรวจสอบโฟลเดอร์',
    },
    tubePaw: {
        learnMore: 'เรียนรู้เพิ่มเติม >',
        productInfo: 'ดาวน์โหลดวิดีโอ/เสียง/คำบรรยายจากเว็บไซต์มากกว่า 10,000 แห่งและแปลงเป็น MP4/WebM/MP3/WAV ในคุณภาพสูง',
        tubePawInfo: 'เครื่องมือดาวน์โหลดวิดีโอและเพลงที่ดีที่สุดสำหรับ Android ที่รองรับเว็บไซต์มากกว่า 10,000 เว็บไซต์ บันทึกไฟล์สื่อเป็นรูปแบบ MP4/MP3/WAV คุณภาพสูง'
    },
    hitPawVideoConverterPop: {
        productName: 'Video Downloader for Desktop',
        productText: 'ดาวน์โหลดวิดีโอ 8K/4K/2K/HD จาก YouTube และเว็บไซต์ยอดนิยมอื่น ๆ มากกว่า 10,000 แห่ง TubePaw Downloader สำหรับเดสก์ท็อปเป็นเครื่องมือแบบครบวงจรในการดาวน์โหลดวิดีโอ/เสียง/คำบรรยาย/ภาพขนาดย่อในคุณภาพสูง แปลงวิดีโอและไฟล์เสียงระหว่าง 1,000 รูปแบบ และแก้ไขวิดีโอ/ไฟล์เสียงเพื่อเพิ่มประสบการณ์วิดีโอของคุณ เรียนรู้เพิ่มเติม.',
        learnMore: 'เรียนรู้เพิ่มเติม',
        tryItFree: 'ทดลองใช้ฟรี',
        online: 'ออนไลน์',
        desktop: 'เดสก์ท็อป',
        td1: 'ดาวน์โหลดวิดีโอ 8K/4K/2K/HD',
        td2: 'รองรับแพลตฟอร์มอื่นๆ เช่น Dailymotion, Bandcamp, Mixcloud เป็นต้น',
        td3: 'ดาวน์โหลดวิดีโอเป็นกลุ่ม',
        td4: 'ดาวน์โหลดเพลย์ลิสต์และช่องต่างๆ',
        td5: 'เร่งความเร็ว GPU',
        td6: 'ดาวน์โหลดเพลงจาก Spotify, Deezer, Apple Music เป็นต้น',
        td7: 'มีเสถียรภาพสูง',
        td8: 'ไม่มีขีดจำกัดขนาดไฟล์',
        proposalPop: "ตามกฎของ YouTube วิดีโอที่มีความละเอียดต่ำกว่า 720p ที่ดาวน์โหลดออนไลน์จะไม่มีเสียง (วิดีโอเงียบ) ข้อเสนอแนะของเรามีดังนี้:",
        proposal_1: "1. ใช้การดาวน์โหลด 4 เพื่อดาวน์โหลดวิดีโอและเสียงแยกกันและรวมเข้าด้วยกัน",
        proposal_2: "2. ใช้ผลิตภัณฑ์เดสก์ท็อป TubePaw Downloader เพื่อดาวน์โหลดวิดีโอ และคุณจะได้รับวิดีโอที่รวมเสียงและวิดีโอเข้าด้วยกัน",
        downloadOnline_txt: "ดาวน์โหลดออนไลน์ต่อไป",
        downloadDesktop_txt: "ดาวน์โหลดผลิตภัณฑ์เดสก์ท็อป",
    },
    hitPawTubePawAppPop: {
        productName: 'Video Downloader for Android',
        productText: 'Best Video&Music Downloader for Android that supports 10000+ websites, saving media files as high-quality MP4/MP3/WAV formats.',
        learnMore: 'เรียนรู้เพิ่มเติม'
    }
}