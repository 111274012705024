export default {
    form: {
        button: 'अभी शुरू करें',
        proposal: 'अपना वीडियो लिंक यहां पेस्ट करें, जैसे https://www.youtube.com/watch?v=vojBp3s0K8o',
        note: '<strong>अस्वीकरण</strong> :&nbsp; YouTube कॉपीराइटेड वीडियो को कन्वर्ट और डाउनलोड करना YouTube की शर्तों और नियमों का उल्लंघन है। कृपया कॉपीराइट मालिक की अनुमति के बिना सामग्री की कॉपी न करें।',
        note_facebook: '{noteTag} "माफ करें, इस प्रकार के लिंक का वर्तमान में समर्थन नहीं किया जाता" त्रुटि प्राप्त होती है जब आप अपने PC/Mac पर Facebook वीडियो डाउनलोड करने का प्रयास कर रहे हैं? कृपया "डाउनलोड" बटन पर क्लिक करें और कुछ बार पुनः प्रयास करें या {fbNote} पर क्लिक करें यह जानने के लिए कि इसे कैसे ठीक करें।',
        note_name: 'नोट',
        note_facebookCont: 'यहां क्लिक करें',
        note_facebookUrl: 'https://www.download4.cc/guide/how-to-download-facebook-videos-on-pc.html',
        terms_before: 'हमारी सेवाओं का उपयोग करके, आप हमारे {terms} को स्वीकार करते हैं',
        terms_after: 'उपयोग की शर्तें',
        terms_url: 'https://www.download4.cc/in/terms-of-use.html',
        sub_button: "फ्री डाउनलोड",
        sub_app_button: "अभी डाउनलोड करें",
        sub_app_mobile_button: "अभी इंस्टॉल करें",
        sub_fixed_button: "ऐप इंस्टॉल करें",
        buy_now: 'अभी खरीदें'
    },
    loadingCont: 'डाउनलोड लिंक का विश्लेषण किया जा रहा है, कृपया कुछ क्षण प्रतीक्षा करें...',
    failure: {
        fail_title: 'माफ करें, संबंधित डाउनलोड लिंक का विश्लेषण करने में विफल रहे',
        notsupport_title: 'माफ करें, इस प्रकार के लिंक का वर्तमान में समर्थन नहीं किया जाता',
        tips1: '1. सबसे पहले, कृपया "डाउनलोड" बटन पर क्लिक करें और समस्या को हल करने के लिए कुछ बार पुनः प्रयास करें। या यदि आप "कॉपी लिंक" बटन का उपयोग करके Facebook वीडियो डाउनलोड करने का प्रयास कर रहे हैं, तो कृपया वापस Facebook वीडियो पेज पर जाएं और उस वीडियो को खोजें जिसे आप डाउनलोड करना चाहते हैं, वीडियो पर डबल क्लिक करें, और उसकी यूआरएल को सीधे download4.cc पर कॉपी करें और वीडियो को पुनः डाउनलोड करें।',
        tips2: '2. दूसरे, सुनिश्चित करें कि वीडियो/म्यूजिक लिंक का सामान्य रूप से एक्सेस किया जा सकता है और वह सामान्य रूप से प्ले हो रहा है।',
        tips3: '3. तीसरे, अस्थिर नेटवर्क के कारण डाउनलोड लिंक का विश्लेषण नहीं किया जा सकता। कृपया अपना नेटवर्क जांचें या अधिक स्थिर नेटवर्क में स्विच करें और पुनः कई बार प्रयास करें। यदि कई प्रयासों के बाद भी आप विश्लेषण करने में असमर्थ हैं, तो कृपया विफलता संदेश में "{feedbackTag}" पर क्लिक करें और हम जल्द से जल्द समर्थन करने के लिए ठीक करेंगे।',
        tips4: '4. कुछ वीडियो डाउनलोड करने के लिए सॉफ़्टवेयर की आवश्यकता होती है। सॉफ़्टवेयर स्थापित करने के बाद, आप बिना किसी सीमा के सभी ऑनलाइन वीडियो डाउनलोड कर सकते हैं।{subscribeTag}',
        feedback_name: 'प्रतिक्रिया',
        feedback_success_cont: 'प्रतिक्रिया सफल!',
        subscribe_name: 'और जानें >'
    },
    subscribe: {
        title: 'सॉफ़्टवेयर जल्द ही उपलब्ध होगा, आप अभी सदस्यता ले सकते हैं। सदस्यता लेने के बाद, हम आपको जल्द से जल्द मुफ्त वीडियो/म्यूजिक डाउनलोड सॉफ़्टवेयर भेजेंगे!',
        button: 'अभी सदस्यता लें',
        proposal: 'कृपया ईमेल पता दर्ज करें',
        subscribe_success_cont: 'सदस्यता सफल!',
        subscribe_fail_cont: 'अमान्य ईमेल प्रारूप',
    },
    downloadCont: {
        durationPre: 'अवधि',
        tips_title: 'डाउनलोड सुझाव:',
        tips1: '<strong>कंप्यूटर के लिए: </strong>डाउनलोड बटन पर राइट-क्लिक करें और "लिंक को इस रूप में सहेजें" का चयन करें;',
        tips2: '<strong>एंड्रॉइड के लिए: </strong>डाउनलोड बटन को लंबे समय तक दबाएं, "लिंक को इस रूप में सहेजें" का चयन करें;',
        tips3: '<strong>आईओएस के लिए: </strong>इस साइट पर डाउनलोड करने के लिए Documents ऐप के अंतर्निर्मित ब्राउज़र का उपयोग करें।'
    },
    downloadOther: {
        video: 'वीडियो',
        audio: 'ऑडियो',
        audio_and_video: 'वीडियो और ऑडियो',
        silent_video: 'मौन वीडियो',
        quality: 'गुणवत्ता',
        format: 'स्वरूप',
        size: 'आकार',
        download: 'डाउनलोड',
        note: '{noteTag}: क्या आपको आवश्यक स्वरूप नहीं मिल रहा है? किसी भी स्वरूप को डाउनलोड करने के लिए क्लिक करें, फिर, इसे आवश्यक स्वरूप में बदलने के लिए {hitpawTag} का उपयोग करें।',
        note_name: 'नोट',
        hitpaw_url: 'https://www.vcows.net/purchase/buy-vcows.html',
        hfv_converter: 'डेस्कटॉप के लिए वीडियो डाउनलोडर',
    },
    downloadMp3: {
        wait_title: 'mp3 डाउनलोड हो रहा है',
        wait_down1: 'इसमें कुछ समय लग सकता है...',
        wait_down2: 'कृपया फ़ाइल को डाउनलोड के लिए तैयार करने की प्रतीक्षा करें...',
        success_title: 'डाउनलोड सफल रहा',
        success_cont: 'कृपया फ़ोल्डर की जांच करें।',
        fail_cont: 'विश्लेषण विफल! कृपया TubePaw डाउनलोडर का उपयोग करके डेस्कटॉप पर पुनः प्रयास करें।',
        too_large: 'माफ करें, बहुत बड़े वीडियो से ऑडियो निकालना समर्थित नहीं है।'
    },
    downloadThu: {
        thumbnail: 'थंबनेल',
        download: 'डाउनलोड',
        wait_title: 'थंबनेल डाउनलोड हो रहा है',
        wait_down1: 'इसमें कुछ समय लग सकता है...',
        wait_down2: 'कृपया फ़ाइल को डाउनलोड के लिए तैयार करने की प्रतीक्षा करें...',
        success_title: 'डाउनलोड सफल रहा',
        success_cont: 'कृपया फ़ोल्डर की जांच करें।',
    },
    downloadSubTit: {
        subtitle: 'उपशीर्षक',
        languages: 'भाषाएं',
        noSubTit: 'माफ करें, इस वीडियो के लिए कोई उपशीर्षक उपलब्ध नहीं है।',
        onlyCC: 'केवल वे वीडियो जिनमें CC होता है, उन्हें डाउनलोड किया जा सकता है। वीडियो में एम्बेडेड उपशीर्षक समर्थित नहीं हैं।',
        wait_title: 'उपशीर्षक डाउनलोड हो रहा है',
        wait_down1: 'इसमें कुछ समय लग सकता है...',
        wait_down2: 'कृपया फ़ाइल को डाउनलोड के लिए तैयार करने की प्रतीक्षा करें...',
        success_title: 'डाउनलोड सफल रहा',
        success_cont: 'कृपया फ़ोल्डर की जांच करें।',
    },
    tubePaw: {
        learnMore: 'और जानें >',
        productInfo: '10000+ वेबसाइटों से वीडियो/ऑडियो/उपशीर्षक डाउनलोड करें और उच्च गुणवत्ता में MP4/WebM/MP3/WAV में परिवर्तित करें।',
        tubePawInfo: 'Android के लिए सर्वश्रेष्ठ वीडियो और म्यूजिक डाउनलोडर जो 10000+ वेबसाइटों का समर्थन करता है, उच्च गुणवत्ता वाले MP4/MP3/WAV स्वरूपों के रूप में मीडिया फ़ाइलों को सहेजता है।'
    },
    hitPawVideoConverterPop: {
        productName: 'TubePaw डाउनलोडर फॉर डेस्कटॉप',
        productText: 'YouTube और अन्य 10000+ लोकप्रिय साइटों से 8K/4K/2K/HD वीडियो डाउनलोड करें। TubePaw डाउनलोडर फॉर डेस्कटॉप एक ऑल-इन-वन टूल है जो वीडियो/ऑडियो/उपशीर्षक/थंबनेल को उच्च गुणवत्ता में डाउनलोड करता है, 1000 स्वरूपों के बीच वीडियो और ऑडियो को परिवर्तित करता है, और आपके वीडियो अनुभव को समृद्ध करने के लिए वीडियो/ऑडियो संपादित करता है।',
        learnMore: 'और जानें',
        tryItFree: 'फ्री में आजमाएं',
        online: 'ऑनलाइन',
        desktop: 'डेस्कटॉप',
        td1: '8K/4K/2K/HD वीडियो डाउनलोड करें',
        td2: 'Dailymotion,Bandcamp,Mixcloud आदि जैसे अधिक प्लेटफार्मों का समर्थन करता है।',
        td3: 'वीडियो को बल्क में डाउनलोड करें',
        td4: 'प्लेलिस्ट और चैनल डाउनलोड करें',
        td5: 'GPU त्वरितीकरण',
        td6: 'वीडियो संपादन विशेषताएं',
        td7: '1000 स्वरूपों के बीच परिवर्तित करें',
        td8: 'बैच रूपांतरण',
        td9: 'मजबूत स्थिरता',
        td10: 'कोई फ़ाइल आकार सीमा नहीं',
        proposalPop: "YouTube की नीतिगत प्रतिबंधों के कारण, 720p से कम वीडियो डाउनलोड करने से मूक वीडियो (कोई ऑडियो नहीं) प्राप्त होगा। हमारे सुझाव इस प्रकार हैं:",
        proposal_1: "1. वीडियो और ऑडियो को अलग से डाउनलोड करने के लिए Download4 का उपयोग करें, फिर उन्हें मिलाएं।",
        proposal_2: "2. ऑडियो और वीडियो दोनों के साथ वीडियो डाउनलोड करने के लिए हमारे डेस्कटॉप उत्पाद, TubePaw डाउनलोडर का उपयोग करें।",
        downloadOnline_txt: "ऑनलाइन डाउनलोड जारी रखें",
        downloadDesktop_txt: "डेस्कटॉप उत्पाद डाउनलोड करें",
    },
    hitPawTubePawAppPop: {
        productName: 'वीडियो डाउनलोड ऐप - TubePaw',
        productText: 'Android के लिए सर्वश्रेष्ठ वीडियो और म्यूजिक डाउनलोडर जो 10000+ वेबसाइटों का समर्थन करता है, उच्च गुणवत्ता वाले MP4/MP3/WAV स्वरूपों के रूप में मीडिया फ़ाइलों को सहेजता है।',
        learnMore: 'और जानें'
    }
}