export default {
    form: {
        button: '立即開始',
        proposal: '在此處貼上您的影片鏈接,例如https://www.youtube.com/watch?v=vojBp3s0K8o',
        note: '<strong>免責聲明</strong> ：&nbsp; 轉換和下載YouTube的版權影片違反了YouTube的條款和條件。沒有版權所有者的許可，請不要複製內容。',
        note_facebook: '{noteTag} 嘗試在您的 PC/Mac 上下載 Facebook 視頻時收到錯誤“抱歉，目前不支持這種類型的鏈接”？ 請單擊“下載”按鈕再試幾次或{fbNote}了解如何修復它。 ',
        note_name: '免責聲明',
        note_facebookCont: '點擊這裡',
        note_facebookUrl: 'https://www.download4.cc/guide/how-to-download-facebook-videos-on-pc.html',
        terms_before: '通過使用我們的服務，您接受我們的{terms}',
        terms_after: '使用條款',
        terms_url: 'https://www.download4.cc/zh/terms-of-use.html',
        sub_button: "免費下載",
        sub_app_button: "現在下載",
        sub_app_mobile_button: "現在安裝",
        sub_fixed_button: "安裝APP",
        buy_now: "立即購買"
    },
    loadingCont: '正在分析下載鏈接，請稍等...',
    failure: {
        fail_title: '抱歉，下載對應的下載鏈接失敗',
        notsupport_title: '抱歉，目前不支持此類鏈接',
        tips1: '1. 首先，請點擊“下載”按鈕再試幾次以解決問題。 或者，如果您嘗試使用從 Facebook 中“複製鏈接”按鈕複製的鏈接下載 Facebook 視頻，請返回 Facebook 視頻頁面並找到您要下載的視頻，雙擊該視頻，然後直接複製其 URL 到 download4.cc 再次下載視頻。',
        tips2: '2. 其次，確保視頻/音樂鏈接可以正常訪問和播放。',
        tips3: '3. 第三，下載鏈接可能因為網絡不穩定而無法解析。 請檢查您的網絡或切換到更穩定的網絡，然後再試幾次。 如果多次嘗試仍無法解析，請點擊失敗提示中的“{feedbackTag}”，我們將盡快修復支持。',
        tips4: '4. 部分視頻需要軟件下載。 安裝軟件後，您可以無限制地下載所有在線視頻。{subscribeTag}',
        feedback_name: '反饋',
        feedback_success_cont: '反饋成功',
        subscribe_name: '詳情了解>'
    },
    subscribe: {
        title: '該軟件即將推出，您可以立即訂閱。 訂閱後，我們會盡快將免費的視頻/音樂下載軟件發送給您！',
        button: '現在訂閱',
        proposal: '請輸入電子郵件地址',
        subscribe_success_cont: '訂閱成功！',
        subscribe_fail_cont: '電子郵件格式無效',
    },
    downloadCont: {
        durationPre: '期間',
        tips_title: '下載提示',
        tips1: '<strong>電腦版：</strong>右擊下載按鈕，選擇“鏈接另存為”進行下載；',
        tips2: '<strong>安卓：</strong>長按下載鍵，選擇“鏈接另存為”下載；',
        tips3: '<strong>iOS 版：</strong>使用 Documents App 的內置瀏覽器在本站下載。'
    },
    downloadOther: {
        video: '視頻',
        audio: '音頻',
        audio_and_video: '視頻和音頻',
        silent_video: '無聲視頻',
        quality: '質量',
        format: '格式',
        size: '尺寸',
        download: '下載',
        note: '{noteTag}: 找不到您需要的格式？ 點擊任意格式下載，然後使用 {hitpawTag} 將其轉換為您需要的格式。',
        note_name: '注意',
        hitpaw_url: 'https://www.vcows.net/purchase/buy-vcows.html',
        hfv_converter: '桌面影片下載器',
    },
    downloadMp3: {
        wait_title: '下載mp3',
        wait_down1: '可能需要一點時間……',
        wait_down2: '正在準備下載文件，請稍候...',
        success_title: '下載成功',
        success_cont: '請檢查文件夾。',
        fail_cont: '解析失敗，請下載桌面影片下載工具重試！',
        too_large: '抱歉，不支持從太大的視頻中提取音頻。'
    },
    downloadThu: {
        thumbnail: '縮圖',
        download: '下載',
        wait_title: '正在下載縮圖',
        wait_down1: '可能需要一點時間……',
        wait_down2: '正在準備下載文件，請稍候...',
        success_title: '下載成功',
        success_cont: '請檢查文件夾。',
    },
    downloadSubTit: {
        subtitle: '字幕',
        languages: '語言',
        noSubTit: '抱歉，此視頻沒有可用的字幕。',
        onlyCC: '只能下載有cc的視頻。 不支持嵌入視頻的字幕。',
        wait_title: '正在下載字幕',
        wait_down1: '可能需要一點時間……',
        wait_down2: '正在準備下載文件，請稍候...',
        success_title: '下載成功',
        success_cont: '請檢查文件夾。',
    },
    tubePaw: {
        learnMore: '了解更多',
        productInfo: '從10000+網站上下載影片/音訊/字幕以及高質量的轉換成MP4/WebM/MP3/WAV。',
        tubePawInfo: '最強安卓影音下載器，支持10000+網站，可將媒體文件保存為高品質的MP4/MP3/WAV等格式。'
    },
    hitPawVideoConverterPop: {
        productName: 'Video Downloader for Desktop',
        productText: '從 YouTube 和其他 10000 多個熱門網站下載 8K/4K/2K/HD 影片。 Video Downloader for Desktop 是一款多合一工具，可下載高質量的影片/音訊/字幕/縮略圖，在 1000 種格式之間轉換影片和音訊，並編輯影片/音訊以豐富您的影片體驗。',
        learnMore: '了解更多',
        tryItFree: '免費下載',
        online: '在線版',
        desktop: '桌面版',
        td1: '下載 8K/4K/2K/高清影片',
        td2: '支援更多平臺，如 Dailymotion、Bandcamp、Mixcloud 等。',
        td3: '批量下載影片',
        td4: "下載播放清單和頻道",
        td5: 'GPU 加速',
        td6: '影片編輯功能',
        td7: '在 1000 種格式之間轉換',
        td8: '批量轉換',
        td9: '穩定性強',
        td10: '沒有檔大小限制',
        proposalPop: "由於youtube規則限制，線上端下載720p以下的影片是沒有聲音的（silent video)。我們的建議如下：",
        proposal_1: "1. 用download 4 分別下載影片和音訊，進行合併；",
        proposal_2: "2. 使用我們的桌面產品TubePaw Downloader下載影片，則可以獲得音影片合一的影片；",
        downloadOnline_txt: "繼續線上下載",
        downloadDesktop_txt: "下載桌面產品",
    },
    hitPawTubePawAppPop: {
        productName: 'Video Downloader for Android',
        productText: '最強安卓影音下載器，支持10000+網站，可將媒體文件保存為高品質的MP4/MP3/WAV等格式。',
        learnMore: '了解更多'
    }
}