export default {
    form: {
        button: 'inizia ora',
        proposal: 'Incolla qui il link del video, ad esempio https://www.youtube.com/watch?v=vojBp3s0K8o',
        note: '<strong>Dichiarazione di non responsabilità</strong> :&nbsp;  la conversione e il download di video protetti da copyright di YouTube violano i termini e le condizioni di YouTube. Si prega di non copiare i contenuti senza il permesso del proprietario del copyright.',
        note_facebook: '{noteTag}: Nota: ricevi l\'errore "Spiacenti, questo tipo di collegamento non è attualmente supportato" quando provi a scaricare video di Facebook sul tuo PC/Mac? Fare clic sul pulsante "Download" per riprovare più volte o fare {fbNote} per sapere come risolverlo.',
        note_name: 'Nota',
        note_facebookCont: 'clic qui',
        note_facebookUrl: 'https://www.download4.cc/guide/how-to-download-facebook-videos-on-pc.html',
        terms_before: 'Utilizzando i nostri servizi, accetti i nostri {terms}',
        terms_after: 'termini di utilizzo',
        terms_url: 'https://www.download4.cc/it/terms-of-use.html',
        sub_button: 'Download Gratis',
        sub_app_button: 'Scarica ora',
        sub_app_mobile_button: "Installa ora",
        sub_fixed_button: "Installa l'applicazione",
        buy_now: "Acquista ora"
    },
    loadingCont: 'Analizzando il link per il download, attendere un momento...',
    failure: {
        fail_title: 'Spiacenti, impossibile scaricare il link di download corrispondente',
        notsupport_title: 'Spiacenti, questo tipo di collegamento non è attualmente supportato',
        tips1: '1. In primo luogo, fare clic sul pulsante "DOWNLOAD" per riprovare più volte a risolvere il problema. Oppure se stai provando a scaricare i video di Facebook utilizzando il collegamento copiato dal pulsante "Copia collegamento" in Facebook, torna alla pagina dei video di Facebook e trova il video che desideri scaricare, fai doppio clic sul video e copia direttamente il suo URL a download4.cc per scaricare nuovamente il video.',
        tips2: '2. In secondo luogo, assicurarsi che sia possibile accedere e riprodurre normalmente il collegamento video/musica.',
        tips3: '3. In terzo luogo, il collegamento per il download potrebbe non essere analizzato a causa della rete instabile. Controlla la tua rete o passa a una più stabile e riprova più volte. Se non sei ancora in grado di analizzare dopo più tentativi, fai clic su "{feedbackTag}" nella richiesta di errore e risolveremo il problema per l\'assistenza il prima possibile.',
        tips4: '4. Alcuni video richiedono il download di un software. Dopo aver installato il software, puoi scaricare tutti i video online senza alcun limite.{subscribeTag}',
        feedback_name: 'Feedback',
        feedback_success_cont: 'Successo del feedback',
        subscribe_name: 'Scopri di più>'
    },
    subscribe: {
        title: 'Il software uscirà presto, puoi iscriverti ora. Dopo l\'iscrizione, ti invieremo il software di download gratuito di video/musica il prima possibile!',
        button: 'Iscriviti ora',
        proposal: 'Si prega di inserire l\'indirizzo e-mail',
        subscribe_success_cont: 'Iscriviti successo!',
        subscribe_fail_cont: 'formato email non valido',
    },
    downloadCont: {
        durationPre: 'Durata',
        tips_title: 'Scarica suggerimenti:',
        tips1: '<strong>Per computer: </strong>fare clic con il pulsante destro del mouse sul pulsante di download e selezionare "Salva collegamento con nome" per scaricare;',
        tips2: '<strong>Per Android: </strong>premere a lungo il pulsante di download, selezionare "Salva collegamento con nome" per scaricare;',
        tips3: '<strong>Per iOS: </strong>utilizzare il browser integrato dell\'app Documenti per scaricare su questo sito.'
    },
    downloadOther: {
        video: 'Video',
        audio: 'Audio',
        audio_and_video: 'Video e audio',
        silent_video: 'Video muto',
        quality: 'Qualità',
        format: 'Formato',
        size: 'Taglia',
        download: 'Scarica',
        note: '{noteTag}: non riesci a trovare i formati che ti servono?Fai clic su qualsiasi formato per scaricarlo, quindi usa il {hitpawTag} per convertirlo nel formato che ti serve.',
        note_name: 'Nota',
        hitpaw_url: 'https://www.vcows.net/purchase/buy-vcows.html',
        hfv_converter: 'Scaricatore video per desktop',
    },
    downloadMp3: {
        wait_title: 'Download di mp3',
        wait_down1: 'Potrebbe volerci qualche istante...',
        wait_down2: 'Attendi mentre il file viene preparato per il download...',
        success_title: 'Scarica con successo',
        success_cont: 'Si prega di controllare la cartella.',
        fail_cont: ' Analisi fallita! Utilizza Downloader Video per desktop per riprovare.',
        too_large: 'Spiacenti, l\'estrazione dell\'audio da video troppo grandi non è supportata.'
    },
    downloadThu: {
        thumbnail: 'Miniatura',
        download: 'SCARICA',
        wait_title: 'Download della miniatura',
        wait_down1: 'Potrebbe volerci qualche istante...',
        wait_down2: 'Attendi mentre il file viene preparato per il download...',
        success_title: 'Scarica con successo',
        success_cont: 'Si prega di controllare la cartella.',
    },
    downloadSubTit: {
        subtitle: 'Sottotitolo',
        languages: 'Le lingue',
        noSubTit: 'Spiacenti, non ci sono sottotitoli disponibili per questo video.',
        onlyCC: 'Solo il video ha cc può essere scaricato. I sottotitoli incorporati nel video non sono supportati.',
        wait_title: 'Download dei sottotitoli',
        wait_down1: 'Potrebbe volerci qualche istante...',
        wait_down2: 'Attendi mentre il file viene preparato per il download...',
        success_title: 'Scarica con successo',
        success_cont: 'Si prega di controllare la cartella.',
    },
    tubePaw: {
        learnMore: 'SCOPRI DI PIÙ',
        productInfo: 'Scarica video/audio/sottotitoli da oltre 10000 siti Web e converte in MP4/WebM/MP3/WAV in alta qualità.',
        tubePawInfo: 'Miglior downloader di video e musica per Android che supporta oltre 10000 siti Web, salvando i file multimediali nei formati MP4/MP3/WAV di alta qualità.'
    },
    hitPawVideoConverterPop: {
        productName: 'Video Downloader for Desktop',
        productText: 'Scarica video 8K/4K/2K/HD da YouTube e altri oltre 10000 siti popolari. Downloader Video  per desktop è uno strumento all-in-one per scaricare video/audio/sottotitoli/miniature in alta qualità, convertire video e audio tra 1000 formati e modificare video/audio per arricchire la tua esperienza video.',
        learnMore: 'SCOPRI DI PIÙ',
        tryItFree: 'PROVA GRATIS',
        online: 'in linea',
        desktop: 'Desktop',
        td1: 'Scarica video 8K/4K/2K/HD',
        td2: 'Supporta più piattaforme come Dailymotion, Bandcamp, Mixcloud ecc.',
        td3: 'Scarica video in blocco',
        td4: "Download playlist e canali",
        td5: 'Accelerazione GPU',
        td6: 'Funzionalità di montaggio video',
        td7: 'Conversione tra 1000 formati',
        td8: 'Conversione batch',
        td9: 'Stabilità forte',
        td10: 'Nessun limite di dimensione del file',
        proposalPop: "A causa delle restrizioni della politica di YouTube, il download di video online con risoluzione inferiore a 720p risulterà in video silenziosi (senza audio). I nostri suggerimenti sono i seguenti:",
        proposal_1: "1. Utilizzare Download4 per scaricare il video e l'audio separatamente, quindi unirli.",
        proposal_2: "2. Utilizza il nostro prodotto desktop, TubePaw Downloader, per scaricare video con audio e video combinati.",
        downloadOnline_txt: "Continua a scaricare online",
        downloadDesktop_txt: "Scarica il prodotto desktop",
    },
    hitPawTubePawAppPop: {
        productName: 'Video Downloader for Android',
        productText: 'Miglior downloader di video e musica per Android che supporta oltre 10000 siti Web, salvando i file multimediali nei formati MP4/MP3/WAV di alta qualità.',
        learnMore: 'SCOPRI DI PIÙ'
    }
}