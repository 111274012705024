export default {
    form: {
        button: 'Start Now',
        proposal: 'Paste your video link here, e.g. https://www.youtube.com/watch?v=vojBp3s0K8o',
        note: '<strong>Disclaimer</strong> :&nbsp; Converting & Downloading YouTube copyrighted videos violate YouTube\'s terms and conditions. Please don\'t copy content without permission of the copyright owner.',
        note_facebook: '{noteTag} Get the error "Sorry, this type of link is not supported currently" when trying to download Facebook video on your PC/Mac? Please click "Download" button to try several times again or {fbNote} to know how to fix it.',
        note_name: 'Note',
        note_facebookCont: 'click here',
        note_facebookUrl: 'https://www.download4.cc/guide/how-to-download-facebook-videos-on-pc.html',
        terms_before: 'By using our services, you accept our {terms}',
        terms_after: 'terms of use',
        terms_url: 'https://www.download4.cc/terms-of-use.html',
        sub_button: "Free Download",
        sub_app_button: "Download Now",
        sub_app_mobile_button: "Install Now",
        sub_fixed_button: "Install App",
        buy_now: 'Buy Now'
    },
    loadingCont: 'Analyzing the download link, please wait for a moment...',
    failure: {
        fail_title: 'Sorry, failed to analyze the corresponding download link',
        notsupport_title: 'Sorry, this type of link is not supported currently',
        tips1: '1. Firstly, please click the “DOWNLOAD” button to try several times again to fix the problem. Or if you are trying to download the Facebook videos using the link copied from the "Copy Link" button in Facebook, please come back to Facebook video page and find the video you want to download, double click the video, and copy its URL directly to download4.cc to download the video again.',
        tips2: '2. Secondly, make sure that the video/music link can be accessed and played normally.',
        tips3: '3. Thirdly, the download link may not be analyzed due to the unstable network. Please check your network or switch to more stable one and try again several times. If you are still unable to analyze after multiple attempts, please click "{feedbackTag}" in the failure prompt and we will fix to support as soon as possible.',
        tips4: '4. Some videos require software to download. After installing the software, you can download all online videos without any limits.{subscribeTag}',
        feedback_name: 'Feedback',
        feedback_success_cont: 'Feedback success!',
        subscribe_name: 'Learn More >'
    },
    subscribe: {
        title: 'The software will come out soon, you can subscribe now. After subscribing, we will send the free video/music download software to you as soon as possible!',
        button: 'Subscribe now',
        proposal: 'Please enter email address',
        subscribe_success_cont: 'Subscribe success!',
        subscribe_fail_cont: 'Invalid email format',
    },
    downloadCont: {
        durationPre: 'Duration',
        tips_title: 'Download tips:',
        tips1: '<strong>For Computer: </strong>Right-click the download button and select "Save Link As" to download;',
        tips2: '<strong>For Android: </strong>Long press the download button, select "Save Link As" to download;',
        tips3: '<strong>For iOS: </strong>Use the built-in browser of the Documents App to download on this site.'
    },
    downloadOther: {
        video: 'Video',
        audio: 'Audio',
        audio_and_video: 'Video & Audio',
        silent_video: 'Silent Video',
        quality: 'Quality',
        format: 'Format',
        size: 'Size',
        download: 'Download',
        note: '{noteTag}: Cannot find the formats you need? Click any format to download, then, use {hitpawTag} to convert it to the format you need.',
        note_name: 'Note',
        hitpaw_url: 'https://www.vcows.net/purchase/buy-vcows.html',
        hfv_converter: 'Video Downloader for Desktop',
    },
    downloadMp3: {
        wait_title: 'Downloading mp3',
        wait_down1: 'it may take a few moment...',
        wait_down2: 'Please wait while the file is being prepared for downloading...',
        success_title: 'Download successfully',
        success_cont: 'Please check the folder.',
        fail_cont: 'Analyze failed! Please use Video Downloader for Desktop to try again. ',
        too_large: 'Sorry, extracting audio from videos that are too large is not supported.'
    },
    downloadThu: {
        thumbnail: 'Thumbnail',
        download: 'DOWNLOAD',
        wait_title: 'Downloading thumbnail',
        wait_down1: 'it may take a few moment...',
        wait_down2: 'Please wait while the file is being prepared for downloading...',
        success_title: 'Download successfully',
        success_cont: 'Please check the folder.',
    },
    downloadSubTit: {
        subtitle: 'Subtitle',
        languages: 'Languages',
        noSubTit: 'Sorry, there is no subtitle avaiable for this video.',
        onlyCC: 'Only video has cc can be downloaded. Subtitle embeded in video is not supported.',
        wait_title: 'Downloading subtitle',
        wait_down1: 'it may take a few moment...',
        wait_down2: 'Please wait while the file is being prepared for downloading...',
        success_title: 'Download successfully',
        success_cont: 'Please check the folder.',
    },
    tubePaw: {
        learnMore: 'Learn More >',
        productInfo: 'Download videos/audio/subtitles from 10000+ websites and convert to MP4/WebM/MP3/WAV in high quality.',
        tubePawInfo: 'Best Video&Music Downloader for Android that supports 10000+ websites, saving media files as high-quality MP4/MP3/WAV formats.',
        buyNow:"Buy Now"

    },
    hitPawVideoConverterPop: {
        productName: 'Video Downloader for Desktop',
        productText: 'Download 8K/4K/2K/HD videos from YouTube and other 10000+ popular sites. Video Downloader for Desktop is an all-in-one tool to download video/audio/subtitle/thumbnail in high quality, convert video&audio between 1000 formats, and edit video/audio to enrich your video experience.',
        learnMore: 'Learn More',
        tryItFree: 'TRY IT FREE',
        online: 'Online',
        desktop: 'Desktop',
        td1: 'Download 8K/4K/2K/HD videos',
        td2: 'Supports more platforms such as Dailymotion,Bandcamp,Mixcloud etc.',
        td3: 'Download videos in bulk',
        td4: 'Download playlist and channels',
        td5: 'Download YouTube Livestream',
        td6: 'Download Music from Spotify/Apple Music/Deezer ...',
        td7: 'GPU acceleration',
        td8: 'Strong stability',
        td9: 'No file size limit',
        proposalPop: "Due to YouTube's policy restrictions, downloading videos below 720p online will result in silent videos (no audio). Our suggestions are as follows:",
        proposal_1: "1. Use Download4 to download the video and audio separately, then merge them.",
        proposal_2: "2. Use a desktop product to download videos with both audio and video combined.",
        downloadOnline_txt: "Continue Downloading Online",
        downloadDesktop_txt: "Download Desktop Product",
        buyDesktop_txt: "Buy Desktop Product",
    },
    hitPawTubePawAppPop: {
        productName: 'Video Downloader for Android',
        productText: 'Best Video&Music Downloader for Android that supports 10000+ websites, saving media files as high-quality MP4/MP3/WAV formats.',
        learnMore: 'Learn More'
    }
}