export default {
    form: {
        button: 'Comece Agora',
        proposal: 'Cole o link do seu vídeo aqui, por exemplo https://www.youtube.com/watch?v=vojBp3s0K8o',
        note: '<strong>Isenção de responsabilidade</strong> :&nbsp; Converter & Baixar vídeos protegidos por direitos autorais do YouTube viola os termos e condições do YouTube. Por favor, não copie conteúdo sem permissão do proprietário dos direitos autorais.',
        note_facebook: '{noteTag} Recebeu o erro “Desculpe, este tipo de link não é compatível no momento” ao tentar baixar um vídeo do Facebook em seu PC / Mac? Clique no botão "Baixar" para tentar várias vezes novamente ou {fbNote} aqui para saber como consertar.',
        note_name: 'Nota',
        note_facebookCont: 'Clique aqui',
        note_facebookUrl: 'https://www.download4.cc/guide/how-to-download-facebook-videos-on-pc.html',
        terms_before: 'Para usar nossos serviços, você aceita nosso {terms}',
        terms_after: 'termos de uso',
        terms_url: 'https://www.download4.cc/pt/terms-of-use.html',
        sub_button: "Download Grátis",
        sub_app_button: "Baixe Agora",
        sub_app_mobile_button: "instale agora",
        sub_fixed_button: "Instalar aplicativo",
        buy_now: "Comprar Agora"
    },
    loadingCont: 'Analisando o link de download, por favor espere um momento...',
    failure: {
        fail_title: 'Não foi possível analisar o link de download correspondente',
        notsupport_title: 'Desculpe, este tipo de link não é suportado',
        tips1: '1. Em primeiro lugar, clique no botão “DOWNLOAD” para tentar várias vezes novamente para corrigir o problema. Ou se você estiver tentando baixar os vídeos do Facebook usando o link copiado do botão "Copiar link" no Facebook, volte à página de vídeo do Facebook e encontre o vídeo que deseja baixar, clique duas vezes no vídeo e copie seu URL diretamente para download4.cc para baixar o vídeo novamente.',
        tips2: '2. Em segundo lugar, certifique-se de que o link de vídeo / música pode ser acessado e reproduzido normalmente.',
        tips3: '3. Em terceiro lugar, o link de download não pode ser analisado devido à rede instável. Verifique sua rede ou mude para uma mais estável e tente novamente várias vezes. Se você ainda não conseguir analisar após várias tentativas, clique em "{feedbackTag}" no prompt de falha e corrigiremos o erro o mais rápido possível.',
        tips4: '4. Alguns vídeos requerem o download do aplicativo. Após instalar, você pode realizar o download de todos os vídeos online sem limites.{subscribeTag}',
        feedback_name: 'Feedback',
        feedback_success_cont: 'Sucesso do feedback!',
        subscribe_name: 'Saber mais>'
    },
    subscribe: {
        title: 'O aplicativo está disponível em breve, você pode se inscrever agora. Depois de fazer isso, nós lhe enviaremos o software de download de vídeo / música grátis assim que possível!',
        button: 'Inscreva-se agora',
        proposal: 'Por favor insira o endereço de email',
        subscribe_success_cont: 'Sucesso na inscrição!',
        subscribe_fail_cont: 'Invalid email format',
    },
    downloadCont: {
        durationPre: 'Duração',
        tips_title: 'Tipos de download:',
        tips1: '<strong>Para Computador: </strong>Um clique direito no botão de download e selecione "Salvar link como" para baixar;',
        tips2: '<strong>Para android: </strong>Um clique direito no botão de download e selecione "Salvar link como" para baixar;',
        tips3: '<strong>For iOS: </strong>Use o navegador do aplicativo Documentos para fazer o download neste site.'
    },
    downloadOther: {
        video: 'Vídeo',
        audio: 'Audio',
        audio_and_video: 'Vídeo & Audio',
        silent_video: 'Silenciar vídeo',
        quality: 'Qualidade',
        format: 'Formato',
        size: 'Tamanho',
        download: 'Download',
        note: '{noteTag}: Não consegue encontrar os formatos de que precisa? Clique em qualquer formato para fazer o download e, em seguida, use {hitpawTag} para convertê-lo para o formato que você precisa.',
        note_name: 'Nota',
        hitpaw_url: 'https://www.vcows.net/purchase/buy-vcows.html',
        hfv_converter: 'Downloader de vídeo para desktop',
    },
    downloadMp3: {
        wait_title: 'Baixando mp3',
        wait_down1: 'Pode demorar um pouco...',
        wait_down2: 'Aguarde enquanto o arquivo está sendo preparado para download...',
        success_title: 'Download com sucesso',
        success_cont: 'Verifique a pasta.',
        fail_cont: 'Falha na análise! Use o Carregador de Vídeo para Desktop e tente novamente.',
        too_large: 'Não é possível extrair áudio de vídeos muito grandes.'
    },
    downloadThu: {
        thumbnail: 'Miniatura',
        download: 'DOWNLOAD',
        wait_title: 'Baixando miniatura',
        wait_down1: 'Pode demorar um pouco...',
        wait_down2: 'Aguarde enquanto o arquivo está sendo preparado para download...',
        success_title: 'Download com sucesso',
        success_cont: 'Verifique a pasta.',
    },
    downloadSubTit: {
        subtitle: 'Subtítulo',
        languages: 'Línguas',
        noSubTit: 'Desculpe, não há legenda disponível para este vídeo.',
        onlyCC: 'Apenas o vídeo com cc pode ser baixado. A legenda incorporada no vídeo não é suportada.',
        wait_title: 'Baixando legenda',
        wait_down1: 'Pode demorar um pouco...',
        wait_down2: 'Aguarde enquanto o arquivo está sendo preparado para download...',
        success_title: 'Download com sucesso',
        success_cont: 'Verifique a pasta.',
    },
    tubePaw: {
        learnMore: 'SAIBA MAIS',
        productInfo: 'Baixa vídeos / áudios / legendas de mais de 10.000 sites e converte para MP4 / WebM / MP3 / WAV em alta qualidade.',
        tubePawInfo: 'O melhor baixador de vídeo e áudio para Android que suporte 10000+sites e salva arquivos de mídia como formatos MP4/MP3/WAV de alta qualidade.'
    },
    hitPawVideoConverterPop: {
        productName: 'Video Downloader for Desktop',
        productText: 'Baixar vídeos 8K/4K/2K/HD do YouTube e outros mais de 10.000 sites populares. O Carregador de Vídeo para Desktop é uma ferramenta completa para baixar vídeo/áudio/legenda/miniatura em alta qualidade, converter vídeo e áudio entre 1000 formatos e editar vídeo/áudio para enriquecer sua experiência de vídeo.',
        learnMore: 'SAIBA MAIS',
        tryItFree: 'TESTE GRÁTIS',
        online: 'Conectados',
        desktop: 'Computador',
        td1: 'Baixe vídeos 8K/4K/2K/HD',
        td2: 'Suporta mais plataformas como Dailymotion, Bandcamp, Mixcloud etc.',
        td3: 'Baixar vídeos em massa',
        td4: 'Baixar playlist e canais',
        td5: 'Aceleração da GPU',
        td6: 'Recursos de edição de vídeo',
        td7: 'Converter entre 1000 formatos',
        td8: 'Conversão em lote',
        td9: 'Estabilidade forte',
        td10: 'Sem limite de tamanho de arquivo',
        proposalPop: "Devido às restrições de política do YouTube, baixar vídeos abaixo de 720p online resultará em vídeos silenciosos (sem áudio). Nossas sugestões são as seguintes:",
        proposal_1: "1. Use o Download4 para baixar o vídeo e o áudio separadamente e depois mescle-os.",
        proposal_2: "2. Use nosso produto para desktop, TubePaw Downloader, para baixar vídeos com áudio e vídeo combinados.",
        downloadOnline_txt: "Continuar Baixando Online",
        downloadDesktop_txt: "Baixar Produto para Desktop",
    },
    hitPawTubePawAppPop: {
        productName: 'Video Downloader for Android',
        productText: 'O melhor baixador de vídeo e áudio para Android que suporte 10000+sites e salva arquivos de mídia como formatos MP4/MP3/WAV de alta qualidade.',
        learnMore: 'SAIBA MAIS'
    }
}