export default {
    form: {
        button: 'Empezar Ahora',
        proposal: 'Pega el enlace de video aquí, por ejemplo, https://www.youtube.com/watch?v=vojBp3s0K8o',
        note: '<strong>Nota </strong> :&nbsp; Convertir y Descargar Videos de YouTube con derechos de autor es una violación de los términos y condiciones de YouTube. Por favor, no copie el contenido sin el permiso de los derechos de autor.',
        note_facebook: '{noteTag} ¿Aparece el error "Lo siento, este tipo de enlace no es compatible actualmente" al intentar descargar videos de Facebook en su PC / Mac? Haga clic en el botón "Descargar" para intentarlo varias veces o {fbNote} saber como arreglarlo.',
        note_name: 'Nota',
        note_facebookCont: 'Haga clic aquí',
        note_facebookUrl: 'https://www.download4.cc/guide/how-to-download-facebook-videos-on-pc.html',
        terms_before: 'Para usar nuestros servicios, debe aceptar nuestros {terms}',
        terms_after: 'términos de uso',
        terms_url: 'https://www.download4.cc/es/terms-of-use.html',
        sub_button: "Descargar Gratis",
        sub_app_button: "Descargar ahora",
        sub_app_mobile_button: "Instalar ahora",
        sub_fixed_button: "Instalar aplicación",
        buy_now: "Comprar Ahora"
    },
    loadingCont: 'Analizando el enlace de descarga, por favor espera un momento...',
    failure: {
        fail_title: 'Lo siento, el análisis del enlace de descarga ha fallado',
        notsupport_title: 'Lo siento, este tipo de enlace no es compatible en este momento',
        tips1: '1. En primer lugar, haga clic en el botón "DESCARGAR" para intentar varias veces de nuevo solucionar el problema. O si está intentando descargar los videos de Facebook usando el enlace copiado del botón "Copiar enlace" en Facebook, regrese a la página de videos de Facebook y busque el video que desea descargar, haga doble clic en el video y copie su URL directamente. to download4.cc para descargar el video nuevamente.',
        tips2: '2. Asegúrate de que el enlace de vídeo/música es accesible y se puede reproducir con normalidad.',
        tips3: '3. En tercer lugar, es posible que el enlace de descarga no se analice debido a la inestabilidad de la red. Verifique su red o cambie a una más estable y vuelva a intentarlo varias veces. Si aún no puede analizar después de varios intentos, haga clic en "{feedbackTag}" en el mensaje de rror y lo solucionaremos lo antes posible.',
        tips4: '4. Algunos vídeos requieren la instalación de un software. Después de instalar el software, podrás descargar todos los vídeos sin ningún tipo de límite.{subscribeTag}',
        feedback_name: 'Retroalimentación',
        feedback_success_cont: 'Feedback success!',
        subscribe_name: 'CONOZCA MÁS'
    },
    subscribe: {
        title: 'El software será lanzado próximamente. Puedes suscribirte a la lista ahora y en cuanto esté disponible te avisaremos directamente en tu email.',
        button: 'Suscribirse ahora',
        proposal: 'Please enter email address',
        subscribe_success_cont: 'Subscribe success!',
        subscribe_fail_cont: 'Invalid email format',
    },
    downloadCont: {
        durationPre: 'Duración',
        tips_title: 'Consejos para la descarga:',
        tips1: '<strong>Para ordenador: </strong>Haz click sobre el botón con el botón derecho y selecciona la opción "Guardar enlace como" para descargar;',
        tips2: '<strong>Para Android: </strong>Mantén presionado el botón de descarga y selecciona la opción "Guardar enlace como" para descargar;',
        tips3: '<strong>Para iOS: </strong>Utiliza el navegador integrado en la app Archivos para descargar los archivos.'
    },
    downloadOther: {
        video: 'Vídeo',
        audio: 'Audio',
        audio_and_video: 'Vídeo & Audio',
        silent_video: 'Vídeo sin audio',
        quality: 'Calidad',
        format: 'Formato',
        size: 'Tamaño',
        download: 'Descargar',
        note: '{noteTag}: ¿No puede encontrar los formatos que necesita? Haga clic en cualquier formato para descargar, luego, utilizar {hitpawTag} para convertirlo al formato que necesite.',
        note_name: 'Nota',
        hitpaw_url: 'https://www.vcows.net/purchase/buy-vcows.html',
        hfv_converter: 'Descargador de videos para escritorio',
    },
    downloadMp3: {
        wait_title: 'Descargando mp3',
        wait_down1: 'Puede que tarde unos minutos...',
        wait_down2: 'Espere mientras se prepara el archivo para su descarga...',
        success_title: 'Descarga con éxito',
        success_cont: 'Compruebe la carpeta.',
        fail_cont: '¡Falta del análisis! Por favor descarga Descargador de Vídeo para Escritorio y pruébalo de nuevo.',
        too_large: 'Lo sentimos, no se admite la extracción de audio de videos que son demasiado grandes.'
    },
    downloadThu: {
        thumbnail: 'Miniatura',
        download: 'DESCARGAR',
        wait_title: 'Descargando miniatura',
        wait_down1: 'Puede que tarde unos minutos...',
        wait_down2: 'Espere mientras se prepara el archivo para su descarga...',
        success_title: 'Descarga con éxito',
        success_cont: 'Compruebe la carpeta.',
    },
    downloadSubTit: {
        subtitle: 'Subtitular',
        languages: 'Idiomas',
        noSubTit: 'Lo sentimos, no hay subtítulos disponibles para este video.',
        onlyCC: 'Solo se puede descargar el video con cc. No se admiten subtítulos incrustados en el video.',
        wait_title: 'Descargando subtítulo',
        wait_down1: 'Puede que tarde unos minutos...',
        wait_down2: 'Espere mientras se prepara el archivo para su descarga...',
        success_title: 'Descarga con éxito',
        success_cont: 'Compruebe la carpeta.',
    },
    tubePaw: {
        learnMore: 'Aprende más>',
        productInfo: 'Descargar vídeos/audio/subtítulos de 10000+ sitios web y convertir a MP4/WebM/MP3/WAV con alta calidad.',
        tubePawInfo: 'El Mejor Descargador de Video y Audio para Android que admite 10000+ sitios web y guarda archivos multimedia como formatos MP4/MP3/WAV de alta calidad.'
    },
    hitPawVideoConverterPop: {
        productName: 'Video Downloader for Desktop',
        productText: 'Descargar 8K/4K/2K/HD vídeos de YouTube y más de 10000 sitios web populares.Descargador de Vídeo para Ecritorio es una herramieta todo-en-uno para convertir vídeos y audio entre 1000 formatos, descargar vídeo/audio/subtítulos/minatura. También se puede editar vídeos y audio para enriquecer la experiencia de vídeo.',
        learnMore: 'CONOZCA MÁS',
        tryItFree: 'PRUÉBALO GRATIS',
        online: 'En línea',
        desktop: 'Computadora',
        td1: 'Descargar 8K/4K/2K/HD vídeos',
        td2: 'Soporte más plataformas como Dailymotion, Bandcamp, Mixcloud, etc.',
        td3: 'Descargar vídeos por lotes',
        td4: 'Lista y canal de descarga',
        td5: 'Aceración de GPU',
        td6: 'Función de editar vídeo',
        td7: 'Convertir entre 1000 formatos',
        td8: 'Convertir por lotes',
        td9: 'Fuerte Estabilidad',
        td10: 'Sin limitación de tamaño de archivo',
        proposalPop: "Debido a las restricciones de la política de YouTube, la descarga de videos en línea por debajo de 720p resultará en videos silenciosos (sin audio). Te sugerimos:",
        proposal_1: "1. Utiliza Download4 para descargar el video y el audio por separado, y luego únelos;",
        proposal_2: "2. Utiliza nuestro producto de escritorio, TubePaw Downloader para descargar videos con audio y video combinados.",
        downloadOnline_txt: "Seguir descargar en Línea",
        downloadDesktop_txt: "Descargar escritorio",
    },
    hitPawTubePawAppPop: {
        productName: 'Video Downloader for Android',
        productText: 'El Mejor Descargador de Video y Audio para Android que admite 10000+ sitios web y guarda archivos multimedia como formatos MP4/MP3/WAV de alta calidad.',
        learnMore: 'Aprende más'
    }
}