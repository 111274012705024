export default {
    form: {
        button: "Commencer maintenant",
        proposal: "Collez le lien de votre vidéo ici, par exemple https://www.youtube.com/watch?v=vojBp3s0K8o",
        note: "<strong>Avertissement</strong> :&nbsp; La conversion et le téléchargement de vidéos YouTube protégées par des droits d'auteur enfreignent les conditions générales de YouTube. Veuillez ne pas copier le contenu sans l'autorisation du titulaire des droits d'auteur.",
        note_facebook: "{noteTag} Vous obtenez l'erreur « Désolé, ce type de lien n'est pas pris en charge actuellement » lorsque vous essayez de télécharger une vidéo Facebook sur votre PC/Mac ? Veuillez cliquer sur le bouton « Télécharger » pour réessayer plusieurs fois ou sur {fbNote} pour savoir comment résoudre ce problème.",
        note_name: "Note",
        note_facebookCont: "Cliquez ici",
        note_facebookUrl: "https://www.download4.cc/guide/how-to-download-facebook-videos-on-pc.html",
        terms_before: "En utilisant nos services, vous acceptez nos {conditions}",
        terms_after: "conditions d'utilisation",
        terms_url: "https://www.download4.cc/fr/terms-of-use.html",
        sub_button: "Téléchargement gratuit",
        sub_app_button: "Télécharger maintenant",
        sub_app_mobile_button: "Installer maintenant",
        sub_fixed_button: "Installer l'application",
        buy_now: "Acheter maintenant"
    },
    loadingCont: "Analyse du lien de téléchargement, veuillez patienter un instant...",
    failure: {
        fail_title: "Désolé, l'analyse du lien de téléchargement correspondant a échoué",
        notsupport_title: "Désolé, ce type de lien n'est pas pris en charge actuellement",
        tips1: "1. Tout d'abord, veuillez cliquer sur le bouton « TÉLÉCHARGER » pour réessayer plusieurs fois pour résoudre le problème. Ou si vous essayez de télécharger les vidéos Facebook en utilisant le lien copié à partir du bouton « Copier le lien » dans Facebook, revenez à la page vidéo Facebook et recherchez la vidéo que vous souhaitez télécharger, double-cliquez sur la vidéo et copiez son URL directement sur download4.cc pour télécharger à nouveau la vidéo.",
        tips2: "2. Deuxièmement, assurez-vous que le lien vidéo/musique est accessible et peut être lu normalement.",
        tips3: "3. Troisièmement, le lien de téléchargement peut ne pas être analysé en raison du réseau instable. Veuillez vérifier votre réseau ou passer à un réseau plus stable et réessayer plusieurs fois. Si vous ne parvenez toujours pas à analyser après plusieurs tentatives, veuillez cliquer sur « {feedbackTag} » dans l'invite d'échec et nous corrigerons le problème dès que possible.",
        tips4: "4. Certaines vidéos nécessitent un logiciel pour être téléchargées. Après avoir installé le logiciel, vous pouvez télécharger toutes les vidéos en ligne sans aucune limite.{subscribeTag}",
        feedback_name: "Commentaires",
        feedback_success_cont: "Commentaires réussis !",
        subscribe_name: "En savoir plus >"
    },
    subscribe: {
        title: "Le logiciel sortira bientôt, vous pouvez vous abonner dès maintenant. Après vous être abonné, nous vous enverrons le logiciel gratuit de téléchargement de vidéos/musiques dès que possible !",
        button: "Abonnez-vous maintenant",
        proposal: "Veuillez saisir votre adresse e-mail",
        subscribe_success_cont: "Inscription réussie !",
        subscribe_fail_cont: "Format d'e-mail non valide",
    },
    downloadCont: {
        durationPre: "Durée",
        tips_title: "Conseils de téléchargement :",
        tips1: "<strong>Pour ordinateur : </strong>Cliquez avec le bouton droit sur le bouton de téléchargement et sélectionnez « Enregistrer le lien sous » pour télécharger ;",
        tips2: "<strong>Pour Android : </strong>Appuyez longuement sur le bouton de téléchargement, sélectionnez « Enregistrer le lien sous » pour télécharger ;",
        tips3: "<strong>Pour iOS : </strong>Utilisez le navigateur intégré de l'application Documents pour télécharger sur ce site."

    },
    downloadOther: {
        video: "Vidéo",
        audio: "Audio",
        audio_and_video: "Vidéo et audio",
        silent_video: "Vidéo silencieuse",
        quality: "Qualité",
        format: "Format",
        size: "Taille",
        download: "Télécharger",
        note: "{noteTag}: Vous ne trouvez pas les formats dont vous avez besoin ? Cliquez sur n'importe quel format à télécharger, puis utilisez {hitpawTag} pour le convertir au format dont vous avez besoin.",
        note_name: "Remarque",
        hitpaw_url: "https://www.vcows.net/purchase/buy-vcows.html",
        hfv_converter: "Video Downloader for Desktop",
    },
    downloadMp3: {
        wait_title: "Téléchargement de mp3",
        wait_down1: "cela peut prendre quelques instants...",
        wait_down2: "Veuillez patienter pendant que le fichier est en cours de préparation pour le téléchargement...",
        success_title: "Téléchargement réussi",
        success_cont: "Veuillez vérifier le dossier.",
        fail_cont: "L'analyse a échoué ! Veuillez utiliser Video Downloader for Desktop pour réessayer.",
        too_large: "Désolé, l'extraction audio de vidéos trop volumineuses n'est pas prise en charge."
    },
    downloadThu: {
        thumbnail: "Miniature",
        download: "TÉLÉCHARGER",
        wait_title: "Miniature de téléchargement",
        wait_down1: "cela peut prendre quelques instants...",
        wait_down2: "Veuillez patienter pendant que le fichier est en cours de préparation pour le téléchargement...",
        success_title: "Téléchargement réussi",
        success_cont: "Veuillez vérifier le dossier.",
    },
    downloadSubTit: {
        subtitle: "Sous-titre",
        languages: "Langues",
        noSubTit: "Désolé, il n'y a pas de sous-titre disponible pour cette vidéo.",
        onlyCC: "Seules les vidéos avec copie conforme peuvent être téléchargées. Les sous-titres intégrés dans la vidéo ne sont pas pris en charge.",
        wait_title: "Téléchargement des sous-titres",
        wait_down1: "cela peut prendre quelques instants...",
        wait_down2: "Veuillez patienter pendant que le fichier est en cours de préparation pour le téléchargement...",
        success_title: "Téléchargement réussi",
        success_cont: "Veuillez vérifier le dossier.",
    },
    tubePaw: {
        learnMore: "En savoir plus >",
        productInfo: "Téléchargez des vidéos/audios/sous-titres à partir de plus de 10 000 sites Web et convertissez-les en MP4/WebM/MP3/WAV en haute qualité.",
        tubePawInfo: "Meilleur téléchargeur de vidéo et de musique pour Android qui prend en charge plus de 10 000 sites Web, enregistrant des fichiers multimédias aux formats MP4/MP3/WAV de haute qualité."
    },
    hitPawVideoConverterPop: {
        productName: "Video Downloader for Desktop",
        productText: "Téléchargez des vidéos 8K/4K/2K/HD depuis YouTube et plus de 10 000 autres sites populaires. TubePaw Downloader pour ordinateur de bureau est un outil tout-en-un pour télécharger des vidéos/audios/sous-titres/vignettes en haute qualité, convertir des vidéos et des audios entre 1 000 formats et éditer des vidéos/audios pour enrichir votre expérience vidéo.",
        learnMore: "En savoir plus",
        tryItFree: "ESSAYEZ-LE GRATUITEMENT",
        online: "En ligne",
        desktop: "Bureau",
        td1: "Téléchargez des vidéos 8K/4K/2K/HD",
        td2: "Prend en charge davantage de plateformes telles que Dailymotion, Bandcamp, Mixcloud, etc.",
        td3: "Téléchargez des vidéos en masse",
        td4: "Téléchargez des listes de lecture et des chaînes",
        td5: "Accélération GPU",
        td6: "Téléchargez de la musique depuis Spotify, Deezer, Apple Music, etc.",
        td7: "Forte stabilité",
        td8: "Aucune limite de taille de fichier",
        proposalPop: "En raison des restrictions imposées par la politique de YouTube, le téléchargement en ligne de vidéos d'une taille inférieure à 720p se traduira par des vidéos muettes(sans son). Nos suggestions sont les suivantes : ",
        proposal_1: "1. Utilisez Download4 pour télécharger la vidéo et l'audio séparément, puis fusionnez-les.",
        proposal_2: "2. Utilisez notre produit de bureau - TubePaw Downloader pour télécharger des vidéos avec le son.",
        downloadOnline_txt: "Continuer à Télécharger en Ligne",
        downloadDesktop_txt: "Télécharger Produit de Bureau",
    },
    hitPawTubePawAppPop: {
        productName: "Video Downloader for Android",
        productText: "Meilleur téléchargeur de vidéos et de musique pour Android qui prend en charge plus de 10 000 sites Web et enregistre des fichiers multimédias aux formats MP4/MP3/WAV de haute qualité.",
        learnMore: "En savoir plus"
    }
}