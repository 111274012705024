import Vue from 'vue'
import App from './App.vue'
import i18n from './lang'

Vue.config.productionTip = false

new Vue({
  i18n,
  render: h => h(App)
}).$mount('#app')

window.onload = function () {
  document.getElementById('banner-close').addEventListener('click', function (e) {
    e.preventDefault()
    document.getElementById('side-banner').classList.add('hidden')
  })

  let install_fixed = document.querySelector('.install-fixed');
  function getElementTop(el) {
    let actualTop = el.offsetTop
    let current = el.offsetParent
    while (current !== null) {
      actualTop += current.offsetTop
      current = current.offsetParent
    }
    return actualTop
  }

  window.addEventListener('scroll', function () {
    let deals = document.querySelector('#tubepaw-app')
    if (!deals) return;
    let deals_realTop = getElementTop(deals)
    let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;

    if (install_fixed) {
      if (scrollTop >= deals_realTop - 100) {
        install_fixed.classList.add("hidden")
        if (scrollTop >= deals_realTop + 80) {
          install_fixed.classList.remove("hidden")
        }
      } else {
        install_fixed.classList.remove("hidden")
      }
    }
  })
}

Vue.config.errorHandler = function (err, vm, info) {
  console.log('Error:' + err.toString() + 'Info:' + info);
}