export default {
    form: {
        button: 'Jetzt starten',
        proposal: 'Fügen Sie hier Ihren Videolink ein, z. B. https://www.youtube.com/watch?v=vojBp3s0K8o',
        note: '<strong>Haftungsausschluss</strong> :&nbsp; Das Konvertieren und Herunterladen urheberrechtlich geschützter YouTube-Videos verstößt gegen die Nutzungsbedingungen von YouTube. Bitte kopieren Sie keine Inhalte ohne die Erlaubnis des Urheberrechtsinhabers.',
        note_facebook: '{noteTag} Erhalten Sie den Fehler "Entschuldigung, dieser Linktyp wird derzeit nicht unterstützt", wenn Sie versuchen, ein Facebook-Video auf Ihrem PC/Mac herunterzuladen? Bitte klicken Sie auf die Schaltfläche "Herunterladen" und versuchen Sie es mehrmals erneut oder klicken Sie auf {fbNote}, um zu erfahren, wie Sie das Problem beheben können.',
        note_name: 'Hinweis',
        note_facebookCont: 'hier klicken',
        note_facebookUrl: 'https://www.download4.cc/guide/how-to-download-facebook-videos-on-pc.html',
        terms_before: 'Durch die Nutzung unserer Dienste akzeptieren Sie unsere {terms}',
        terms_after: 'Nutzungsbedingungen',
        terms_url: 'https://www.download4.cc/de/terms-of-use.html',
        sub_button: "Kostenloser Download",
        sub_app_button: "Jetzt herunterladen",
        sub_app_mobile_button: "Jetzt installieren",
        sub_fixed_button: "App installieren",
        buy_now: 'Jetzt kaufen'
    },
    loadingCont: 'Der Download-Link wird analysiert, bitte warten Sie einen Moment...',
    failure: {
        fail_title: 'Entschuldigung, der entsprechende Download-Link konnte nicht analysiert werden',
        notsupport_title: 'Entschuldigung, dieser Linktyp wird derzeit nicht unterstützt',
        tips1: '1. Klicken Sie zunächst auf die Schaltfläche „HERUNTERLADEN“ und versuchen Sie es mehrmals erneut, um das Problem zu beheben. Wenn Sie versuchen, Facebook-Videos mit dem Link herunterzuladen, der über die Schaltfläche „Link kopieren“ in Facebook kopiert wurde, kehren Sie bitte zur Facebook-Video-Seite zurück und finden Sie das Video, das Sie herunterladen möchten, doppelklicken Sie auf das Video und kopieren Sie dessen URL direkt zu download4.cc, um das Video erneut herunterzuladen.',
        tips2: '2. Stellen Sie zweitens sicher, dass der Video-/Musiklink zugänglich ist und normal abgespielt werden kann.',
        tips3: '3. Drittens kann der Download-Link aufgrund eines instabilen Netzwerks möglicherweise nicht analysiert werden. Bitte überprüfen Sie Ihr Netzwerk oder wechseln Sie zu einem stabileren und versuchen Sie es erneut mehrmals. Wenn Sie nach mehreren Versuchen immer noch nicht analysieren können, klicken Sie bitte auf "{feedbackTag}" in der Fehlermeldung, und wir werden das Problem so schnell wie möglich beheben, um es zu unterstützen.',
        tips4: '4. Einige Videos erfordern zum Herunterladen eine Software. Nach der Installation der Software können Sie alle Online-Videos ohne Einschränkungen herunterladen.{subscribeTag}',
        feedback_name: 'Feedback',
        feedback_success_cont: 'Feedback erfolgreich!',
        subscribe_name: 'Mehr erfahren >'
    },
    subscribe: {
        title: 'Die Software wird bald veröffentlicht, Sie können sich jetzt anmelden. Nach der Anmeldung senden wir Ihnen die kostenlose Video-/Musik-Download-Software so schnell wie möglich zu!',
        button: 'Jetzt abonnieren',
        proposal: 'Bitte E-Mail-Adresse eingeben',
        subscribe_success_cont: 'Anmeldung erfolgreich!',
        subscribe_fail_cont: 'Ungültiges E-Mail-Format',
    },
    downloadCont: {
        durationPre: 'Dauer',
        tips_title: 'Download-Tipps:',
        tips1: '<strong>Für Computer: </strong>Klicken Sie mit der rechten Maustaste auf die Download-Schaltfläche und wählen Sie "Link speichern unter", um herunterzuladen;',
        tips2: '<strong>Für Android: </strong>Halten Sie die Download-Schaltfläche gedrückt und wählen Sie "Link speichern unter", um herunterzuladen;',
        tips3: '<strong>Für iOS: </strong>Verwenden Sie den integrierten Browser der Documents App, um auf dieser Seite herunterzuladen.'
    },
    downloadOther: {
        video: 'Video',
        audio: 'Audio',
        audio_and_video: 'Video & Audio',
        silent_video: 'Stummes Video',
        quality: 'Qualität',
        format: 'Format',
        size: 'Größe',
        download: 'Herunterladen',
        note: '{noteTag}: Können Sie die benötigten Formate nicht finden? Klicken Sie auf ein beliebiges Format, um es herunterzuladen, und verwenden Sie dann {hitpawTag}, um es in das gewünschte Format zu konvertieren.',
        note_name: 'Hinweis',
        hitpaw_url: 'https://www.vcows.net/purchase/buy-vcows.html',
        hfv_converter: 'Video-Downloader für Desktop',
    },
    downloadMp3: {
        wait_title: 'mp3 wird heruntergeladen',
        wait_down1: 'es kann einen Moment dauern...',
        wait_down2: 'Bitte warten Sie, während die Datei für den Download vorbereitet wird...',
        success_title: 'Download erfolgreich',
        success_cont: 'Bitte überprüfen Sie den Ordner.',
        fail_cont: 'Analyse fehlgeschlagen! Bitte verwenden Sie TubePaw Downloader für Desktop, um es erneut zu versuchen.',
        too_large: 'Entschuldigung, das Extrahieren von Audio aus zu großen Videos wird nicht unterstützt.'
    },
    downloadThu: {
        thumbnail: 'Vorschaubild',
        download: 'HERUNTERLADEN',
        wait_title: 'Vorschaubild wird heruntergeladen',
        wait_down1: 'es kann einen Moment dauern...',
        wait_down2: 'Bitte warten Sie, während die Datei für den Download vorbereitet wird...',
        success_title: 'Download erfolgreich',
        success_cont: 'Bitte überprüfen Sie den Ordner.',
    },
    downloadSubTit: {
        subtitle: 'Untertitel',
        languages: 'Sprachen',
        noSubTit: 'Entschuldigung, es ist kein Untertitel für dieses Video verfügbar.',
        onlyCC: 'Nur Videos mit CC können heruntergeladen werden. Im Video eingebettete Untertitel werden nicht unterstützt.',
        wait_title: 'Untertitel wird heruntergeladen',
        wait_down1: 'es kann einen Moment dauern...',
        wait_down2: 'Bitte warten Sie, während die Datei für den Download vorbereitet wird...',
        success_title: 'Download erfolgreich',
        success_cont: 'Bitte überprüfen Sie den Ordner.',
    },
    tubePaw: {
        learnMore: 'Mehr erfahren >',
        productInfo: 'Laden Sie Videos/Audio/Untertitel von über 10.000 Websites herunter und konvertieren Sie sie in hochwertige MP4/WebM/MP3/WAV-Formate.',
        tubePawInfo: 'Der beste Video- und Musik-Downloader für Android, der über 10.000 Websites unterstützt und Mediendateien in hochqualitative MP4/MP3/WAV-Formate speichert.'
    },
    hitPawVideoConverterPop: {
        productName: 'TubePaw Downloader für Desktop',
        productText: 'Laden Sie 8K/4K/2K/HD-Videos von YouTube und über 10.000 beliebten Seiten herunter. TubePaw Downloader für Desktop ist ein All-in-One-Tool zum Herunterladen von Video/Audio/Untertitel/Vorschaubild in hoher Qualität, zum Konvertieren von Video & Audio zwischen 1000 Formaten und zum Bearbeiten von Video/Audio, um Ihr Videoerlebnis zu bereichern.',
        learnMore: 'Mehr erfahren',
        tryItFree: 'KOSTENLOS AUSPROBIEREN',
        online: 'Online',
        desktop: 'Desktop',
        td1: 'Laden Sie 8K/4K/2K/HD-Videos herunter',
        td2: 'Unterstützt mehr Plattformen wie Dailymotion, Bandcamp, Mixcloud usw.',
        td3: 'Laden Sie Videos in großen Mengen herunter',
        td4: 'Laden Sie Playlists und Kanäle herunter',
        td5: 'GPU-Beschleunigung',
        td6: 'Videobearbeitungsfunktionen',
        td7: 'Konvertieren Sie zwischen 1000 Formaten',
        td8: 'Batch-Konvertierung',
        td9: 'Starke Stabilität',
        td10: 'Keine Dateigrößenbeschränkung',
        proposalPop: "Aufgrund der YouTube-Richtlinienbeschränkungen führt das Herunterladen von Videos unter 720p online zu stummen Videos (kein Audio). Unsere Vorschläge sind wie folgt:",
        proposal_1: "1. Verwenden Sie Download4, um das Video und Audio separat herunterzuladen, und fügen Sie sie dann zusammen.",
        proposal_2: "2. Verwenden Sie unser Desktop-Produkt, den TubePaw Downloader, um Videos mit kombiniertem Audio und Video herunterzuladen.",
        downloadOnline_txt: "Weiter online herunterladen",
        downloadDesktop_txt: "Desktop-Produkt herunterladen",
    },
    hitPawTubePawAppPop: {
        productName: 'Video Downloader for Android',
        productText: 'Der beste Video- und Musik-Downloader für Android, der über 10.000 Websites unterstützt und Mediendateien in hochqualitative MP4/MP3/WAV-Formate'
    }
}