<template>
  <div id="App">
    <search-box></search-box>
  </div>
</template>

<script>
import SearchBox from "@/components/SearchBox.vue";
export default {
  name: "App",
  components: {
    SearchBox
  },
}
</script>