export default {
    form: {
        button: 'Начать сейчас',
        proposal: 'Вставьте ссылку на ваше видео здесь, например, https://www.youtube.com/watch?v=vojBp3s0K8o',
        note: '<strong>Отказ от ответственности</strong> :&nbsp; Конвертация и загрузка видео с YouTube, защищенных авторскими правами, нарушает условия использования YouTube. Пожалуйста, не копируйте контент без разрешения владельца авторских прав.',
        note_facebook: '{noteTag} Получаете ошибку "Извините, этот тип ссылки в настоящее время не поддерживается" при попытке загрузить видео с Facebook на вашем ПК/Mac? Пожалуйста, нажмите кнопку "Скачать" и попробуйте еще раз несколько раз, или нажмите {fbNote}, чтобы узнать, как это исправить.',
        note_name: 'Примечание',
        note_facebookCont: 'нажмите здесь',
        note_facebookUrl: 'https://www.download4.cc/guide/how-to-download-facebook-videos-on-pc.html',
        terms_before: 'Используя наши услуги, вы принимаете наши {terms}',
        terms_after: 'условия использования',
        terms_url: 'https://www.download4.cc/ru/terms-of-use.html',
        sub_button: "Бесплатная загрузка",
        sub_app_button: "Скачать сейчас",
        sub_app_mobile_button: "Установить сейчас",
        sub_fixed_button: "Установить приложение",
        buy_now: 'Купить сейчас'
    },
    loadingCont: 'Анализируется ссылка для загрузки, пожалуйста, подождите...',
    failure: {
        fail_title: 'Извините, не удалось проанализировать соответствующую ссылку для загрузки',
        notsupport_title: 'Извините, этот тип ссылки в настоящее время не поддерживается',
        tips1: '1. Во-первых, пожалуйста, нажмите кнопку "СКАЧАТЬ" и попробуйте несколько раз еще раз, чтобы решить проблему. Если вы пытаетесь скачать видео с Facebook, используя ссылку, скопированную с помощью кнопки "Копировать ссылку" на Facebook, вернитесь на страницу видео на Facebook, найдите видео, которое хотите скачать, дважды щелкните по видео и скопируйте его URL напрямую на download4.cc, чтобы снова скачать видео.',
        tips2: '2. Во-вторых, убедитесь, что ссылка на видео/музыку доступна и воспроизводится нормально.',
        tips3: '3. В-третьих, ссылка для загрузки может не анализироваться из-за нестабильного соединения. Проверьте соединение с сетью или переключитесь на более стабильное и попробуйте еще раз несколько раз. Если после нескольких попыток анализ все еще не удается, пожалуйста, нажмите "{feedbackTag}" в сообщении об ошибке, и мы исправим поддержку как можно скорее.',
        tips4: '4. Для загрузки некоторых видео требуется программное обеспечение. После установки программного обеспечения вы сможете загружать все онлайн-видео без ограничений.{subscribeTag}',
        feedback_name: 'Обратная связь',
        feedback_success_cont: 'Обратная связь отправлена!',
        subscribe_name: 'Узнать больше >'
    },
    subscribe: {
        title: 'Программное обеспечение скоро выйдет, вы можете подписаться сейчас. После подписки мы отправим вам бесплатное программное обеспечение для загрузки видео/музыки как можно скорее!',
        button: 'Подписаться сейчас',
        proposal: 'Пожалуйста, введите адрес электронной почты',
        subscribe_success_cont: 'Подписка успешна!',
        subscribe_fail_cont: 'Неверный формат электронной почты',
    },
    downloadCont: {
        durationPre: 'Длительность',
        tips_title: 'Советы по загрузке:',
        tips1: '<strong>Для компьютера: </strong>Щелкните правой кнопкой мыши по кнопке загрузки и выберите "Сохранить ссылку как", чтобы скачать;',
        tips2: '<strong>Для Android: </strong>Нажмите и удерживайте кнопку загрузки, выберите "Сохранить ссылку как", чтобы скачать;',
        tips3: '<strong>Для iOS: </strong>Используйте встроенный браузер приложения Documents для загрузки с этого сайта.'
    },
    downloadOther: {
        video: 'Видео',
        audio: 'Аудио',
        audio_and_video: 'Видео и аудио',
        silent_video: 'Беззвучное видео',
        quality: 'Качество',
        format: 'Формат',
        size: 'Размер',
        download: 'Скачать',
        note: '{noteTag}: Не можете найти нужные форматы? Нажмите на любой формат для загрузки, затем используйте {hitpawTag}, чтобы конвертировать его в нужный вам формат.',
        note_name: 'Примечание',
        hitpaw_url: 'https://www.vcows.net/purchase/buy-vcows.html',
        hfv_converter: 'Загрузчик видео для рабочего стола',
    },
    downloadMp3: {
        wait_title: 'Скачивание mp3',
        wait_down1: 'это может занять некоторое время...',
        wait_down2: 'Пожалуйста, подождите, пока файл готовится к загрузке...',
        success_title: 'Загрузка успешно завершена',
        success_cont: 'Пожалуйста, проверьте папку.',
        fail_cont: 'Не удалось проанализировать! Пожалуйста, используйте TubePaw Downloader для ПК, чтобы попробовать снова.',
        too_large: 'Извините, извлечение аудио из слишком больших видео не поддерживается.'
    },
    downloadThu: {
        thumbnail: 'Миниатюра',
        download: 'СКАЧАТЬ',
        wait_title: 'Скачивание миниатюры',
        wait_down1: 'это может занять некоторое время...',
        wait_down2: 'Пожалуйста, подождите, пока файл готовится к загрузке...',
        success_title: 'Загрузка успешно завершена',
        success_cont: 'Пожалуйста, проверьте папку.',
    },
    downloadSubTit: {
        subtitle: 'Субтитры',
        languages: 'Языки',
        noSubTit: 'Извините, для этого видео нет доступных субтитров.',
        onlyCC: 'Можно скачать только видео с CC. Субтитры, встроенные в видео, не поддерживаются.',
        wait_title: 'Скачивание субтитров',
        wait_down1: 'это может занять некоторое время...',
        wait_down2: 'Пожалуйста, подождите, пока файл готовится к загрузке...',
        success_title: 'Загрузка успешно завершена',
        success_cont: 'Пожалуйста, проверьте папку.',
    },
    tubePaw: {
        learnMore: 'Узнать больше >',
        productInfo: 'Скачивайте видео/аудио/субтитры с более чем 10000 сайтов и конвертируйте их в высококачественные форматы MP4/WebM/MP3/WAV.',
        tubePawInfo: 'Лучший видеозагрузчик и загрузчик музыки для Android, который поддерживает более 10000 сайтов и сохраняет медиафайлы в высококачественных форматах MP4/MP3/WAV.'
    },
    hitPawVideoConverterPop: {
        productName: 'TubePaw Downloader для ПК',
        productText: 'Скачивайте видео в разрешении 8K/4K/2K/HD с YouTube и других более чем 10000 популярных сайтов. TubePaw Downloader для ПК - это универсальный инструмент для загрузки видео/аудио/субтитров/миниатюр в высоком качестве, конвертации видео и аудио между 1000 форматами и редактирования видео/аудио для улучшения вашего видеоопыта.',
        learnMore: 'Узнать больше',
        tryItFree: 'ПОПРОБОВАТЬ БЕСПЛАТНО',
        online: 'Онлайн',
        desktop: 'ПК',
        td1: 'Скачивание видео в разрешении 8K/4K/2K/HD',
        td2: 'Поддерживает больше платформ, таких как Dailymotion, Bandcamp, Mixcloud и т. д.',
        td3: 'Скачивание видео большими партиями',
        td4: 'Скачивание плейлистов и каналов',
        td5: 'GPU-ускорение',
        td6: 'Функции редактирования видео',
        td7: 'Конвертация между 1000 форматами',
        td8: 'Пакетная конвертация',
        td9: 'Высокая стабильность',
        td10: 'Нет ограничений на размер файла',
        proposalPop: "Из-за ограничений политики YouTube загрузка видео с разрешением ниже 720p приведет к получению беззвучных видео (без аудио). Наши предложения следующие:",
        proposal_1: "1. Используйте Download4 для загрузки видео и аудио отдельно, а затем объедините их.",
        proposal_2: "2. Используйте наш настольный продукт, TubePaw Downloader, чтобы скачать видео с аудио и видео вместе.",
        downloadOnline_txt: "Продолжить загрузку онлайн",
        downloadDesktop_txt: "Скачать настольный продукт",

    },
    hitPawTubePawAppPop: {
        productName: 'Приложение для загрузки видео - TubePaw',
        productText: 'Лучший видеозагрузчик и загрузчик музыки для Android, поддерживающий более 10000 сайтов и сохраняющий медиафайлы в высококачественных форматах MP4/MP3/WAV.'
    }
}